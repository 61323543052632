import { useContext, useMemo } from "react";
import * as aq from "arquero";

import {
  PlanContextualDataContext,
  PlanSelectionContext,
  PortfolioUsageTableContext,
} from "lib/contexts";
import { PlanContextualData, VisualizationScope, Wrapper } from "lib/types";
import Transformable, {
  Params,
  Struct,
} from "arquero/dist/types/table/transformable";
import { useAppStore } from "app/hooks/use-app-store";
import {
  filterTableByCalendarSelection,
  filterTableBySpaceSelection,
  filterTableToExcludeCapacityOfOne,
} from "lib/occupancy";
import ColumnTable from "arquero/dist/types/table/column-table";

function filterTableByVisualizationScope(
  table: ColumnTable,
  visualizationScope: VisualizationScope,
  selectedBuilding: string,
  selectedFloor: string
) {
  const filtered = table
    .params({
      visualizationScope,
      selectedBuilding,
      selectedFloor,
    })
    .filter((d: Struct | any, $: Params) => {
      if (
        $.visualizationScope === "building" &&
        d.BUILDING_NAME !== $.selectedBuilding
      )
        return false;
      if ($.visualizationScope === "floor" && d.FLOOR_NAME !== $.selectedFloor)
        return false;

      return true;
    });

  return filtered.reify();
}

export const PlanContextualDataProvider: Wrapper = ({ children }) => {
  const { spaceFunction: spaceFunctionState } = useAppStore();

  const [planSelectionState] = useContext(PlanSelectionContext);

  const { selectedBuilding, selectedFloor, visualizationScope, colorBy } =
    planSelectionState;

  const { table: portfolioTable } = useContext(PortfolioUsageTableContext);

  const { calendar: calendarState } = useAppStore();

  const contextualData = useMemo<PlanContextualData>(() => {
    let filtered = filterTableBySpaceSelection(
      filterTableByVisualizationScope(
        filterTableByCalendarSelection(portfolioTable, calendarState),
        visualizationScope,
        selectedBuilding,
        selectedFloor
      ),
      spaceFunctionState.selected
    );

    filtered =
      colorBy === "density"
        ? filterTableToExcludeCapacityOfOne(filtered)
        : filtered;

    const inter = filtered.groupby("SPACE_ID").rollup({
      density: aq.op.mean("AVG_TEAM_DENSITY_WHEN_USED"),
    });

    const result = inter.rollup({
      minDensity: aq.op.min("density"),
      maxDensity: aq.op.max("density"),
      medianDensity: aq.op.median("density"),
    });

    return result.objects()[0];
  }, [
    portfolioTable,
    colorBy,
    visualizationScope,
    selectedBuilding,
    selectedFloor,
    spaceFunctionState,
    calendarState,
  ]);

  return (
    <PlanContextualDataContext.Provider value={contextualData}>
      {children}
    </PlanContextualDataContext.Provider>
  );
};
