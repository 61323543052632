import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { VisualizationScope } from "lib/types";

type Props = {
  value: VisualizationScope;
  onChange: (value: VisualizationScope) => void;
};

export const ScopeToggle: React.FunctionComponent<Props> = (props) => {
  return (
    <ToggleButtonGroup
      value={props.value}
      exclusive
      size="small"
      onChange={(e, value) => {
        // Ignore nulls to disallow toggling all buttons off
        if (value !== null) {
          props.onChange(value as VisualizationScope);
        }
      }}
    >
      <ToggleButton value="floor">Floor</ToggleButton>
      <ToggleButton value="building">Building</ToggleButton>
      <ToggleButton value="portfolio">Portfolio</ToggleButton>
      <ToggleButton value="industry" disabled>
        Industry
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
