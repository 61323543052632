import * as colors from "@density/proto-ui-colors";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Icons } from "@density/dust";
import * as dust from "@density/dust/dist/tokens/dust.tokens";

import { ActiveView } from "lib/types";
import densityMark from "img/density-logo-mark-light.svg";

const electricBlue = "#00AAFF";

export const LeftMenuBar: React.FunctionComponent<{
  activeView: ActiveView;
  setActiveView: (view: ActiveView) => void;
}> = ({ activeView, setActiveView }) => {
  const colorLinkActive = electricBlue;
  const colorLinkInactive = colors.Gray200;
  return (
    <Box
      sx={{
        width: 40,
        background: "linear-gradient(180deg, #081421 0%, #1C2A59 100%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexShrink: 0,
      }}
    >
      {/* Logo */}
      <img style={{ marginTop: 8 }} width={24} src={densityMark} alt="logo" />

      <Box sx={{ height: 20 }}></Box>

      {/* Atlas dummy link */}
      <Tooltip title="Atlas Benchmark" placement="right">
        <IconButton
          onClick={() => window.location.replace("https://atlas.density.io")}
        >
          <Icons.LightningOutline color={dust.Gray400} />
        </IconButton>
      </Tooltip>

      <Box sx={{ height: 8 }}></Box>

      {/* Map View */}
      <Tooltip title="Map View" placement="right">
        <IconButton
          sx={{
            color: activeView === "map" ? colorLinkActive : colorLinkInactive,
          }}
          onClick={() => setActiveView("map")}
        >
          <Icons.MapFloorPlan />
        </IconButton>
      </Tooltip>

      <Box flex={1}></Box>

      <Box sx={{ height: 8 }}></Box>
      <Tooltip title="Settings" placement="right">
        <IconButton
          sx={{
            color:
              activeView === "settings" ? colorLinkActive : colorLinkInactive,
          }}
          // onClick={() => setActiveView("settings")}
        >
          <Icons.CogGearSettings size={24} color={dust.Gray400} />
        </IconButton>
      </Tooltip>
      <Box height={4}></Box>
      <Tooltip title="Help" placement="right">
        <IconButton>
          <Icons.HelpQuestionMark size={24} color={dust.Gray400} />
        </IconButton>
      </Tooltip>
      <Box height={4}></Box>
    </Box>
  );
};
