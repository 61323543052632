import React, { Dispatch } from "react";
import { AsyncDuckDB } from "@duckdb/duckdb-wasm";
import ColumnTable from "arquero/dist/types/table/column-table";

import {
  PlanSelectionAction,
  PlanSelectionState,
  PlansListItem,
} from "app/state/plan-selection";
import {
  GroupExtents,
  MapColorScale,
  Plan,
  PlanContextualData,
  PrototypeUser,
} from "lib/types";

type UserContextType = [
  user: PrototypeUser,
  setUser: (user: PrototypeUser) => void
];
export const UserContext = React.createContext<
  [PrototypeUser, (user: PrototypeUser) => void]
>(null as unknown as UserContextType);

export const DatabaseContext = React.createContext<AsyncDuckDB>(
  null as unknown as AsyncDuckDB
);

export type PlansContextType = {
  plans: Array<PlansListItem>;
  allOrgNames: Array<string>;
};
export const PlansContext = React.createContext(
  null as unknown as PlansContextType
);
type PlanSelectionContextType = [
  PlanSelectionState,
  Dispatch<PlanSelectionAction>
];
export const PlanSelectionContext =
  React.createContext<PlanSelectionContextType>(
    null as unknown as PlanSelectionContextType
  );

export type IndustryContextType = {
  allOrgNames: Array<string>;
  industryExtents: GroupExtents;
  // industrySpacesResult: Array<SpacesQueryRow>;
};
export const IndustryContext = React.createContext<IndustryContextType>(
  null as unknown as IndustryContextType
);

export type OrganizationContextType = {
  organizationName: string;
  portfolioExtents: GroupExtents;
};
export const OrganizationContext = React.createContext<OrganizationContextType>(
  null as unknown as OrganizationContextType
);

export type PlanContextType = {
  plan: Plan;
};
export const PlanContext = React.createContext<PlanContextType>(
  null as unknown as PlanContextType
);

export type GlobalFilterState = {
  filterUnusedSpaces: boolean;
  enabledSpaceFunctions: Array<string>;
  startDate: string;
  endDate: string;
  startHour: number;
  endHour: number;
};
// export type GlobalFilterActions = {
//   setFilterUnusedSpaces: (enable: boolean) => void;
//   setEnabledSpaceFunctions: (functions: Array<string>) => void;
//   setDateRange: (startDate: string, endDate: string) => void;
// };

// type GlobalFilterContextType = [GlobalFilterState, GlobalFilterActions];
// export const GlobalFilterContext = React.createContext(
//   null as unknown as GlobalFilterContextType
// );

type StaticDataContextType = {
  spaceFunctions: Array<{ name: string; count: number }>;
};
export const StaticDataContext = React.createContext(
  null as unknown as StaticDataContextType
);

type PortfolioUsageTableContextType = {
  table: ColumnTable;
};
export const PortfolioUsageTableContext = React.createContext(
  null as unknown as PortfolioUsageTableContextType
);

export type PortfolioSpacesTableContextType = {
  table: ColumnTable;
};
export const PortfolioSpacesTableContext = React.createContext(
  null as unknown as PortfolioSpacesTableContextType
);

export const ColorScaleContext = React.createContext(
  null as unknown as {
    scaleDensity: MapColorScale;
    scaleTimeUsed: MapColorScale;
  }
);

export const PlanContextualDataContext = React.createContext(
  null as unknown as PlanContextualData
);
