import { useEffect } from "react";

import {
  SpaceSelectionContext,
  useSpaceSelection,
} from "app/state/space-selection";
import { Wrapper } from "lib/types";

export const SpaceSelectionProvider: Wrapper = ({ children }) => {
  const spaceSelection = useSpaceSelection();
  const [, spaceSelectionActions] = spaceSelection;

  // Handle the escape key action here at top-level for now
  useEffect(() => {
    const action = spaceSelectionActions.onEscapeKeyDown;
    const handler = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        action();
      }
    };
    window.document.addEventListener("keydown", handler);

    return () => {
      window.document.removeEventListener("keydown", handler);
    };
  }, [spaceSelectionActions.onEscapeKeyDown]);

  return (
    <SpaceSelectionContext.Provider value={spaceSelection}>
      {children}
    </SpaceSelectionContext.Provider>
  );
};
