import * as d3 from "d3";
import * as dust from "@density/dust/dist/tokens/dust.tokens";

export function generateDensityColorScale(
  minDensity: number,
  medianDensity: number,
  maxDensity: number
) {
  return d3
    .scaleLinear<string>()
    .domain([minDensity, medianDensity, maxDensity])
    .range([dust.Green500, dust.Yellow300, dust.Red500])
    .clamp(true);
}

export function generateTimeUsedColorScale() {
  return d3
    .scaleLinear<string>()
    .domain([1.0, 0.5, 0])
    .range([dust.Green500, dust.Yellow300, dust.Red500])
    .clamp(true);
  // return d3.scaleSequential<string>(d3.interpolateViridis).domain([0, 1]);
  // .range([dust.White, dust.Blue400]);
}
