import { useContext, useState, useEffect } from "react";
import * as arrow from "apache-arrow";

import { DatabaseContext, StaticDataContext } from "lib/contexts";
import { Resolvable } from "lib/resolvable";
import { getStoredObject } from "lib/storage";
import { Wrapper } from "lib/types";
import { ErrorState } from "components/error-state";
import { Loader } from "components/loader";
import { DATASET_PATH } from "lib/dataset";

export const ParquetDataLoader: Wrapper = (props) => {
  const db = useContext(DatabaseContext);

  const [state, setState] = useState<
    Resolvable<{
      spaceFunctions: Array<{ name: string; count: number }>;
    }>
  >({
    status: Resolvable.Status.NONE,
  });

  useEffect(() => {
    setState(Resolvable.updateRunning());
    (async () => {
      // {
      //   const buffer = await getStoredObject(
      //     "combined_occupancy_time_used.parquet"
      //   );
      //   await db.registerFileBuffer("occupancy.parquet", buffer);
      // }

      {
        const buffer = await getStoredObject(
          `${DATASET_PATH}/occupancy_time_used_agg_date.parquet`
        );
        await db.registerFileBuffer(
          "occupancy_time_used_agg_date.parquet",
          buffer
        );
      }

      {
        const buffer = await getStoredObject(
          `${DATASET_PATH}/count_frequency_by_space_by_day.parquet`
        );
        await db.registerFileBuffer(
          "count_frequency_by_space_by_day.parquet",
          buffer
        );
      }

      // plans
      {
        const buffer = await getStoredObject(`${DATASET_PATH}/plans.parquet`);
        await db.registerFileBuffer("plans.parquet", buffer);
      }
      // plan_images
      {
        const buffer = await getStoredObject(
          `${DATASET_PATH}/plan_images.parquet`
        );
        await db.registerFileBuffer("plan_images.parquet", buffer);
      }
      // spaces
      {
        const buffer = await getStoredObject(`${DATASET_PATH}/spaces.parquet`);
        await db.registerFileBuffer("spaces.parquet", buffer);
      }

      const conn = await db.connect();

      // await conn.query(`
      //   CREATE OR REPLACE VIEW occupancy AS (
      //     SELECT *
      //     FROM parquet_scan('occupancy.parquet')
      //   )
      // `);

      // await conn.query(`
      //   CREATE OR REPLACE VIEW occupancy_grouped_by_hour AS (
      //     SELECT *
      //     FROM 'occupancy_grouped_by_hour.parquet'
      //   )
      // `);

      // await conn.query(`
      //   SET s3_region='us-east-1';
      //   `);
      // await conn.query(`
      //   SET s3_access_key_id='${process.env.REACT_APP_BUCKET_ACCESS_KEY_ID}';
      //   `);
      // await conn.query(`
      //   SET s3_secret_access_key='${process.env.REACT_APP_BUCKET_SECRET_ACCESS_KEY}';
      // `);

      await conn.query(`
        CREATE OR REPLACE TABLE plans AS (
          SELECT *
          FROM 'plans.parquet'
        )        
      `);
      await conn.query(`
        CREATE UNIQUE INDEX plans_id_idx ON plans (PLAN_ID)
      `);

      await conn.query(`
        CREATE OR REPLACE VIEW plan_images AS (
          SELECT *
          FROM 'plan_images.parquet'
        )
      `);

      await conn.query(`
        CREATE OR REPLACE TABLE spaces AS (
          SELECT *
          FROM 'spaces.parquet'
          WHERE SPACE_NAME NOT LIKE 'HISTORICAL_%'
          AND SPACE_NAME NOT LIKE '%- DNU'
        )
      `);
      await conn.query(`
        CREATE UNIQUE INDEX spaces_id_idx ON spaces (SPACE_ID)
      `);
      await conn.query(`
        CREATE INDEX spaces_function_idx ON spaces (FUNCTION)
      `);

      await conn.query(`
        CREATE OR REPLACE TABLE spaces_org AS (
          SELECT DISTINCT
            SPACE_ID,
            ORGANIZATION_NAME,
          from spaces
          INNER JOIN plans
          ON plans.PLAN_ID = spaces.PLAN_ID
        )
      `);

      await conn.query(`
        CREATE UNIQUE INDEX spaces_org_space_id_idx ON spaces_org (SPACE_ID)
      `);

      const spaceFunctionsResult = await conn.query<{
        FUNCTION: arrow.Utf8;
        COUNT: arrow.Uint32;
      }>(`
        SELECT
          FUNCTION,
          COUNT(*) AS COUNT, 
        FROM spaces
        WHERE FUNCTION IS NOT NULL
        GROUP BY FUNCTION
        ORDER BY COUNT DESC
      `);

      // await conn.query(`
      //   CREATE OR REPLACE VIEW occupancy AS (
      //     SELECT * FROM 'occupancy.parquet'
      //   )
      // `);

      // //-- Uncomment to print DB mem usage --//
      // const dbSize = await conn.query(`
      //   PRAGMA database_size;
      // `);
      // console.log("dbSize", dbSize.toString());

      await conn.close();
      setState({
        status: Resolvable.Status.COMPLETED,
        value: {
          spaceFunctions: spaceFunctionsResult.toArray().map((d) => {
            return {
              name: d.FUNCTION,
              count: d.COUNT,
            };
          }),
        },
      });
    })();
  }, [db]);

  switch (state.status) {
    case Resolvable.Status.NONE:
    case Resolvable.Status.RUNNING: {
      return <Loader>Loading datasets...</Loader>;
    }
    case Resolvable.Status.FAILED: {
      return <ErrorState>Error</ErrorState>;
    }
    case Resolvable.Status.COMPLETED: {
      return (
        <StaticDataContext.Provider value={state.value}>
          {props.children}
        </StaticDataContext.Provider>
      );
    }
  }
};
