import { Box, Typography } from "@mui/material";
import * as colors from "@density/proto-ui-colors";

import { formatSpaceFunction } from "app/state/space-function";

type Props = {
  name: string;
  count: number;
  isSelected: boolean;
  isDisabled: boolean;
  onClick: () => void;
  color?: string;
};

const noop = () => {};

export const SpaceFunctionChip: React.FunctionComponent<Props> = ({
  name,
  count,
  isSelected,
  isDisabled,
  onClick,
  color = colors.Gray100,
}) => {
  return (
    <Box
      display="inline-flex"
      flexDirection="row"
      alignItems="center"
      sx={{
        cursor: "pointer",
        bgcolor: color,
        color: isSelected ? colors.White : colors.Gray900,
        height: 20,
        borderRadius: "10px",
        px: 1,
        mx: 0.5,
        my: 0.25,
        opacity: isDisabled ? 0.5 : 1,
        "&:hover": {
          opacity: isDisabled ? 0.5 : 0.9,
        },
      }}
      onClick={isDisabled ? noop : onClick}
    >
      <Typography sx={{ fontSize: 11 }}>{formatSpaceFunction(name)}</Typography>
      <Typography sx={{ fontSize: 10, fontWeight: "bold", ml: 1 }}>
        {count}
      </Typography>
    </Box>
  );
};
