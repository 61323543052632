import React, { useMemo } from "react";
import {
  createActionStream,
  createStore,
  Dispatch,
  StoreSubject,
} from "@densityco/lib-state-management";

import { GlobalState, reducer, initialState } from "app/state";
import { GlobalAction } from "app/state/actions";
import { Wrapper } from "lib/types";

type StoreContextType = [StoreSubject<GlobalState>, Dispatch<GlobalAction>];
export const StoreContext = React.createContext([
  null,
  null,
] as any as StoreContextType);

export const StoreProvider: Wrapper = ({ children }) => {
  const [actionStream, dispatch] = useMemo(
    () => createActionStream<GlobalAction>(),
    []
  );
  const store = useMemo(
    () => createStore(reducer, initialState, actionStream),
    [actionStream]
  );
  return (
    <StoreContext.Provider value={[store, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};
