import { useCallback, useMemo } from "react";
import { Box, colors } from "@mui/material";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  getWeeksInMonth,
  getWeekOfMonth,
  lastDayOfWeek,
  isSameMonth,
  getDay,
  getWeek,
  startOfMonth,
  format,
} from "date-fns";
import * as dust from "@density/dust/dist/tokens/dust.tokens";

import {
  DateString,
  realizeDate,
  extractDateString,
  extractDayName,
  CalendarState,
  DateInterval,
  eachUniqueWeekOfInterval,
  extractWeekId,
  extractMonthId,
  CalendarTimeframe,
  DAY_NAMES,
  DayName,
} from "app/state/calendar";
import { CalendarColorData } from "lib/types";
import { Icons } from "@density/dust";

const BACKGROUND_COLOR = dust.White;
const SELECTED_BACKGROUND_COLOR = dust.White;
const SELECTED_DROP_SHADOW = `drop-shadow(0.5px 0.5px 1px ${dust.Gray300})`;
const SELECTED_DISABLED_COLOR = dust.White;
const SELECTED_LABEL_COLOR = dust.Blue400;
const DISABLED_COLOR = dust.White;
const DISABLED_OPACITY = 0.5;

export type CalendarWidgetProps = {
  shownInterval: DateInterval;
  dateSelection: CalendarState;
  colorData?: CalendarColorData;
  tileSize?: number;
  tileLabelSize?: number;
  tilePadding?: number;
  tileRadius?: number;
  padding?: { top: number; right: number; bottom: number; left: number };
  onDayOfWeekClick?: (dayName: DayName) => void;
  onDayMouseEnter?: (date: DateString) => void;
  onDayMouseLeave?: (date: DateString) => void;
  onDayClick?: (date: DateString) => void;
  onTimeframeChange?: (timeframe: CalendarTimeframe) => void;

  // onDayHeaderClick: (dayIndex: 0 | 1 | 2 | 3 | 4 | 5 | 6) => void;
};

export const CalendarWidget: React.FunctionComponent<CalendarWidgetProps> = ({
  shownInterval,
  dateSelection,
  colorData = {},
  tileSize = 20,
  tileLabelSize = 7,
  tilePadding = 2,
  tileRadius = 2,
  padding = {
    top: 24,
    right: 36,
    bottom: 24,
    left: 32,
  },
  onDayOfWeekClick = () => {},
  onDayMouseEnter = () => {},
  onDayMouseLeave = () => {},
  onDayClick = () => {},
  onTimeframeChange = () => {},
}) => {
  const width = useMemo(
    () => padding.left + tileSize * 7 + padding.right,
    [padding, tileSize]
  );

  const realizedInterval = useMemo(() => {
    return {
      start: realizeDate(shownInterval.start),
      end: realizeDate(shownInterval.end),
    };
  }, [shownInterval]);

  const { disabledDayNames, disabledDates } = dateSelection;

  const selectWeek = useCallback(
    (date: Date) => {
      const weekId = extractWeekId(date);
      // Calendar.dispatch({ type: "timeframe.selectedWeek", weekId });
      onTimeframeChange({ type: "week", weekId });
    },
    [onTimeframeChange]
  );

  const selectMonth = useCallback(
    (date: Date) => {
      const monthId = extractMonthId(date);
      // Calendar.dispatch({ type: "timeframe.selectedMonth", monthId });
      onTimeframeChange({ type: "month", monthId });
    },
    [onTimeframeChange]
  );

  // const enabledDates = useMemo(() => {
  //   return new Set(
  //     eachDayOfInterval(realizedInterval).reduce<Array<DateString>>(
  //       (out, date) => {
  //         const dateString = extractDateString(date);
  //         const dayName = extractDayName(date);
  //         if (disabledDates.has(dateString)) {
  //           return out;
  //         }
  //         if (disabledDayNames.has(dayName)) {
  //           return out;
  //         }
  //         out.push(dateString);
  //         return out;
  //       },
  //       []
  //     )
  //   );
  // }, [disabledDates, disabledDayNames, realizedInterval]);

  const scales = useMemo(() => {
    const months = eachMonthOfInterval(realizedInterval);
    const monthWeeks = months.map((d) => {
      return {
        key: format(d, "yyyy-MM"),
        numWeeks: getWeeksInMonth(d),
      };
    });
    const heightInTiles = monthWeeks.reduce((h, d) => h + d.numWeeks, 0);

    const getMonthYOffsetTiles = (date: Date) => {
      const monthKey = format(date, "yyyy-MM");
      let tiles = 0;
      for (const m of monthWeeks) {
        if (m.key === monthKey) return tiles;
        tiles += m.numWeeks;
      }
      return 0;
    };

    const getWeekYOffsetTiles = (date: Date) => {
      const weekNumber = getWeekOfMonth(date);
      return weekNumber - 1;
    };

    const dateToYPosition = (date: Date) => {
      const tiles = getMonthYOffsetTiles(date) + getWeekYOffsetTiles(date);
      return padding.top + tiles * tileSize;
    };

    const dateToXPosition = (date: Date) => {
      const tiles = getDay(date);
      return padding.left + tiles * tileSize;
    };

    return { dateToXPosition, dateToYPosition, heightInTiles };
  }, [padding, tileSize, realizedInterval]);

  const height = padding.top + scales.heightInTiles * tileSize + padding.bottom;

  const dayNumberToXPosition = useCallback(
    (day: number) => {
      return padding.left + day * tileSize;
    },
    [padding, tileSize]
  );

  // const dayData = useMemo(() => {
  //   const output: { [date: string]: CalendarDatum } = {};
  //   overlayData.objects().forEach((row) => {
  //     const key: string = (row as any).LOCAL_DATE;
  //     output[key] = row as CalendarDatum;
  //   });
  //   return output;
  // }, [overlayData]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={width}
    >
      <svg
        width={width}
        height={height}
        overflow="visible"
        style={{ userSelect: "none" }}
      >
        <rect width={width} height={height} fill={BACKGROUND_COLOR} />
        {/* Day of Week Labels */}
        <g textAnchor="middle">
          {DAY_NAMES.map((d, i) => {
            const isActive = !disabledDayNames.has(d);

            const isDisabledForNow = d === "Saturday" || d === "Sunday";

            return (
              <g
                key={i}
                transform={`translate(${dayNumberToXPosition(i)}, 0)`}
                onClick={() => {
                  if (isDisabledForNow) return;
                  onDayOfWeekClick(d);
                }}
                cursor={isDisabledForNow ? "not-allowed" : "pointer"}
              >
                <rect width={tileSize} height={tileSize} fill="transparent" />
                <text
                  dy={"0.34em"}
                  x={tileSize / 2}
                  y={padding.top - 12}
                  fontSize={10}
                  fontFamily="Aeonikmono"
                  fontWeight={isActive ? 700 : 400}
                  fill={isActive ? SELECTED_LABEL_COLOR : dust.Gray400}
                  pointerEvents="none"
                >
                  {d[0]}
                </text>
              </g>
            );
          })}
        </g>
        {/* Months */}
        {eachMonthOfInterval(realizedInterval).map((m, i) => {
          const yPosition = scales.dateToYPosition(m);
          const monthHeight = getWeeksInMonth(m) * tileSize;
          const monthId = extractMonthId(m);
          const isSelected =
            dateSelection.timeframe.type === "month" &&
            dateSelection.timeframe.monthId === monthId;
          return (
            <g
              key={i}
              transform={`translate(0, ${yPosition})`}
              style={{ zIndex: isSelected ? 9999 : 1 }}
            >
              {/* Month background */}
              <rect
                width={width}
                y={-tilePadding / 2}
                height={monthHeight + tilePadding}
                fill={isSelected ? SELECTED_BACKGROUND_COLOR : "transparent"}
                rx={2}
                // filter={isSelected ? SELECTED_DROP_SHADOW : "none"}
              />

              {/* Month label */}
              <g
                transform={`translate(${
                  dayNumberToXPosition(6) + tileSize + 8
                }, ${tileSize / 2})`}
              >
                <text
                  fontFamily="Aeonikmono"
                  fontSize={10}
                  textAnchor="start"
                  dy={"0.34em"}
                  fill={isSelected ? SELECTED_LABEL_COLOR : dust.Gray300}
                  fontWeight={isSelected ? 700 : 400}
                >
                  {format(m, "MMM").toUpperCase()}
                </text>
              </g>
              {/* Month click target */}
              <rect
                x={dayNumberToXPosition(6) + tileSize}
                width={padding.right}
                height={monthHeight}
                fill="transparent"
                cursor="pointer"
                onClick={() => selectMonth(m)}
              />
            </g>
          );
        })}
        {/* Weeks */}
        {eachUniqueWeekOfInterval(realizedInterval).map(({ weekId, date }) => {
          const yPosition = scales.dateToYPosition(date);
          // At the end of a month, a week can span two months...
          const spansTwoMonths: boolean = !isSameMonth(
            date,
            lastDayOfWeek(date)
          );
          const weekHeight = spansTwoMonths ? 2 * tileSize : tileSize;
          const isSelected =
            dateSelection.timeframe.type === "week" &&
            dateSelection.timeframe.weekId === weekId;

          const weekOfMonth = getWeek(date) - getWeek(startOfMonth(date)) + 1;

          return (
            <g
              key={extractDateString(date)}
              transform={`translate(0, ${yPosition})`}
            >
              {/* Week background */}
              <rect
                width={width - padding.right + tilePadding / 2}
                height={weekHeight + tilePadding}
                y={-tilePadding / 2}
                fill={isSelected ? SELECTED_BACKGROUND_COLOR : "transparent"}
                // filter={isSelected ? SELECTED_DROP_SHADOW : "none"}
                rx={2}
              />
              {/* Week label */}
              <text
                dy="0.34em"
                x={padding.left - 8}
                y={tileSize / 2}
                fill={isSelected ? SELECTED_LABEL_COLOR : dust.Gray300}
                fontWeight={isSelected ? 700 : 400}
                fontFamily="Aeonikmono"
                fontSize={10}
                textAnchor="end"
              >
                {`W${weekOfMonth}`}
              </text>
              {/* Week click target */}
              <rect
                width={padding.left}
                height={weekHeight}
                fill="transparent"
                cursor="pointer"
                onClick={() => selectWeek(date)}
              />
            </g>
          );
        })}
        {/* Days */}
        {eachDayOfInterval(realizedInterval).map((date) => {
          const dateString = extractDateString(date);
          const dayName = extractDayName(date);
          const isDisabled = disabledDayNames.has(dayName);

          const isExplicitlyDisabled = disabledDates.has(dateString);

          const weekId = extractWeekId(date);
          const monthId = extractMonthId(date);
          const isSelected =
            (dateSelection.timeframe.type === "month" &&
              dateSelection.timeframe.monthId === monthId) ||
            (dateSelection.timeframe.type === "week" &&
              dateSelection.timeframe.weekId === weekId);

          const disabledColor = isSelected
            ? SELECTED_DISABLED_COLOR
            : DISABLED_COLOR;

          const color = isDisabled
            ? disabledColor
            : colorData[dateString] || disabledColor;

          const hasData = Boolean(colorData[dateString]);

          const xPosition = scales.dateToXPosition(date);
          const yPosition = scales.dateToYPosition(date);

          // const isSelected = enabledDates.has(dateString);

          return (
            <g
              key={dateString}
              opacity={isSelected ? 1 : 0.3}
              transform={`translate(${xPosition}, ${yPosition})`}
              onClick={() => onDayClick(dateString)}
            >
              {/* Tile Background */}
              {/* <rect
                width={tileSize}
                height={tileSize}
                fill={isSelected ? dust.Gray900 : "transparent"}
              /> */}
              {/* Tile */}
              <rect
                x={tilePadding}
                y={tilePadding}
                width={tileSize - 2 * tilePadding}
                height={tileSize - 2 * tilePadding}
                rx={tileRadius}
                // fill={isDisabled ? DISABLED_COLOR : dust.Blue400}
                fill={color}
                // opacity={isDisabled || !hasData ? DISABLED_OPACITY : 1}
                cursor="pointer"
                onMouseEnter={() => onDayMouseEnter(dateString)}
                onMouseLeave={() => onDayMouseLeave(dateString)}
              />
              {/* Day of Month Label */}
              <text
                textAnchor="middle"
                x={tileSize / 2}
                y={tileSize / 2}
                dy={"0.34em"}
                fontFamily="Aeonik"
                fontSize={tileLabelSize}
                fontWeight="bold"
                fill={
                  isDisabled
                    ? dust.Gray300
                    : hasData
                    ? dust.White
                    : dust.Gray300
                }
                pointerEvents="none"
              >
                {format(date, "d")}
              </text>
              {isExplicitlyDisabled ? (
                <g stroke={dust.Gray400} strokeWidth={2}>
                  <line
                    x1={tilePadding - 1}
                    x2={tileSize - tilePadding + 1}
                    y1={tilePadding - 1}
                    y2={tileSize - tilePadding + 1}
                  />
                  <line
                    x1={tilePadding - 1}
                    x2={tileSize - tilePadding + 1}
                    y1={tileSize - tilePadding + 1}
                    y2={tilePadding - 1}
                  />
                </g>
              ) : null}
            </g>
          );
        })}
      </svg>
      <Box height={100}></Box>
    </Box>
  );
};
