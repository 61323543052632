import * as d3 from "d3";
import { Reducer } from "@densityco/lib-state-management";

import { exhaustiveCheck } from "app/state";
import { useAppStore } from "app/hooks/use-app-store";
import { GlobalAction, SpaceFunctionAction } from "./actions";

// Just a string for now; could enumerate exact strings or objects if desired
export type SpaceFunction = string;

export type SpaceFunctionState = {
  selected: Set<SpaceFunction>;
};

export const initialState: SpaceFunctionState = {
  selected: new Set(),
};

export const reducer: Reducer<SpaceFunctionState, GlobalAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "spaceFunction.toggleItem": {
      const { item } = action;
      const exists = state.selected.has(item);
      const next = new Set(state.selected);
      if (exists) {
        next.delete(item);
        return {
          selected: next,
        };
      } else {
        next.add(item);
        return {
          selected: next,
        };
      }
    }
    case "spaceFunction.reset": {
      return { ...initialState };
    }
    default: {
      exhaustiveCheck<SpaceFunctionAction>(action);
      return state;
    }
  }
};

export const isSpaceFunctionSelected = (
  state: SpaceFunctionState,
  spaceFunction: SpaceFunction
) => {
  return state.selected.has(spaceFunction);
};

export const colorizeSpaceFunction = (state: SpaceFunctionState) => {
  const list = Array.from(state.selected);
  const scale = d3.scaleOrdinal(d3.schemeCategory10).domain(list);
  const output: { [spaceFunction: string]: string } = {};
  for (const item of list) {
    output[item] = scale(item);
  }
  return output;
};

export const formatSpaceFunction = (raw: SpaceFunction) => {
  return raw.replaceAll("_", " "); // replace underscores with spaces
};

export function useSpaceFunctionState() {
  const state = useAppStore();
  return state.spaceFunction;
}
