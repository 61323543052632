import { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Person2Sharp } from "@mui/icons-material";
import { TokenProviderContext } from "@densityco/lib-common-auth";
import * as colors from "@density/proto-ui-colors";

import { PlansContext, UserContext } from "lib/contexts";

export const UserMenu: React.FunctionComponent = () => {
  const [user, setUser] = useContext(UserContext);
  const tokenProviderData = useContext(TokenProviderContext);

  const { allOrgNames } = useContext(PlansContext);

  // NOTE: This is not the same as selectedOrg for the visualization
  //       It is the temporary state of the selected org to impersonate.
  const [selectedOrg, setSelectedOrg] = useState<string>(allOrgNames[0]);

  const [customerDemoDialogOpen, setCustomerDemoDialogOpen] = useState(false);
  const [internalUserDialogOpen, setInternalUserDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleUserAvatarClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    // Opens the menu
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    // Closes the menu
    setAnchorEl(null);
  };

  const handleInternalUserDialogClose = () => {
    setInternalUserDialogOpen(false);
  };
  const handleCustomerDemoDialogClose = () => {
    setCustomerDemoDialogOpen(false);
  };

  const initInternalUser = () => {
    setUser({ mode: "superuser" });
  };

  const initCustomerDemo = (organizationName: string) => {
    setUser({ mode: "user", organizationName });
  };

  const isSuperuser = user.mode === "superuser";
  const labelText = isSuperuser
    ? tokenProviderData?.tokenCheckResponse?.user?.email || "team@density.io"
    : user.organizationName;

  return (
    <Box
      display={"flex"}
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-end"
      px={1}
      minWidth={240}
    >
      <FormControl size="small">
        <FormControlLabel
          control={
            <IconButton
              onClick={handleUserAvatarClick}
              size="small"
              sx={{
                color: isSuperuser ? colors.Blue300 : colors.Purple300,
              }}
            >
              <Person2Sharp />
            </IconButton>
          }
          label={
            <Typography variant={"body1"} sx={{ p: 1, color: colors.Gray700 }}>
              {labelText}
            </Typography>
          }
          labelPlacement="start"
        />
      </FormControl>
      <Menu open={menuOpen} anchorEl={anchorEl} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            setInternalUserDialogOpen(true);
            handleMenuClose();
          }}
        >
          Internal User
        </MenuItem>
        <MenuItem
          onClick={() => {
            setCustomerDemoDialogOpen(true);
            handleMenuClose();
          }}
        >
          Customer Demo
        </MenuItem>
      </Menu>

      {/* CUSTOMER DEMO CONFIRMATION DIALOG */}
      <Dialog
        open={customerDemoDialogOpen}
        onClose={handleCustomerDemoDialogClose}
      >
        <DialogTitle>Customer Demo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select the organization to impersonate for the demo.
          </DialogContentText>
          <FormGroup>
            <FormControl>
              <Select
                sx={{ my: 2 }}
                autoFocus
                margin="dense"
                value={selectedOrg}
                onChange={(evt) => setSelectedOrg(evt.target.value)}
              >
                {allOrgNames.map((org) => (
                  <MenuItem key={org} value={org}>
                    {org}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCustomerDemoDialogClose} color="warning">
            Cancel
          </Button>
          <Button
            onClick={() => {
              initCustomerDemo(selectedOrg);
              handleCustomerDemoDialogClose();
            }}
          >
            Start
          </Button>
        </DialogActions>
      </Dialog>

      {/* INTERNAL USER CONFIRMATION DIALOG */}
      <Dialog
        open={internalUserDialogOpen}
        onClose={handleInternalUserDialogClose}
      >
        <DialogTitle>Internal User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure? All organizations will be visible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="warning" onClick={handleInternalUserDialogClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              initInternalUser();
              handleInternalUserDialogClose();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
