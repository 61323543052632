import { useContext, useMemo } from "react";
import * as aq from "arquero";
import { Box, Typography, Divider, ListItem, List } from "@mui/material";
import * as dust from "@density/dust/dist/tokens/dust.tokens";

import {
  PlanSelectionContext,
  PlanContext,
  PortfolioUsageTableContext,
  PlanContextualDataContext,
} from "lib/contexts";
import {
  formatAsInteger,
  formatAsPercent,
  formatAsDollarsAndCents,
  formatAsDollars,
  formatAsTeamDensity,
} from "lib/formatters";

import { LineItemGraphic } from "components/line-item-graphic";
import { MetricDisplay } from "components/metric-display";
import {
  generateDensityColorScale,
  generateTimeUsedColorScale,
} from "lib/color-scale";
import {
  filterTableByCalendarSelection,
  filterTableByPlan,
  filterTableBySpaceSelection,
  filterTableToExcludeCapacityOfOne,
} from "lib/occupancy";
import { Struct } from "arquero/dist/types/table/transformable";
import { useAppStore } from "app/hooks/use-app-store";

export const FloorDetail: React.FunctionComponent = () => {
  const [{ selectedFloor, selectedPlanId, colorBy }] =
    useContext(PlanSelectionContext);
  const { plan } = useContext(PlanContext);

  const { table: portfolioTable } = useContext(PortfolioUsageTableContext);

  const { calendar: calendarState, spaceFunction: spaceFunctionState } =
    useAppStore();

  const { selected: selectedSpaceFunctions } = spaceFunctionState;

  const table = useMemo(() => {
    let table = filterTableByPlan(portfolioTable, selectedPlanId);
    if (colorBy === "density") {
      table = filterTableToExcludeCapacityOfOne(table);
    }
    table = filterTableBySpaceSelection(table, selectedSpaceFunctions);
    table = filterTableByCalendarSelection(table, calendarState);
    return table.reify();
  }, [
    portfolioTable,
    selectedPlanId,
    colorBy,
    selectedSpaceFunctions,
    calendarState,
  ]);

  type Stats = {
    WEIGHTED_TIME_USED_PERCENT: number;
    WEIGHTED_DENSITY_WHEN_USED: number;
    TOTAL_AREA_SQFT: number;
    TOTAL_SPACES: number;
    COST_PER_SQFT: number;
  };

  const stats: Stats | null = useMemo(() => {
    const result = table
      .groupby("SPACE_ID")
      .rollup({
        AREA_SQFT: aq.op.any("AREA_SQFT"),
        COST_PER_SQFT: aq.op.any("COST_PER_SQFT"),
        TIME_USED_PERCENT: aq.op.mean("TIME_USED_PERCENT"),
        AVG_OCCUPANCY_WHEN_USED: aq.op.mean("AVG_OCCUPANCY_WHEN_USED"),
        AVG_TEAM_DENSITY_WHEN_USED: aq.op.mean("AVG_TEAM_DENSITY_WHEN_USED"),
      })
      .derive({
        WEIGHTED_TIME_USED_PERCENT: (d: Struct) =>
          d.TIME_USED_PERCENT * d.AREA_SQFT,
        WEIGHTED_DENSITY_WHEN_USED: (d: Struct) =>
          d.AVG_TEAM_DENSITY_WHEN_USED * d.AREA_SQFT,
      })
      .rollup({
        WEIGHTED_TIME_USED_PERCENT: aq.op.sum("WEIGHTED_TIME_USED_PERCENT"),
        WEIGHTED_DENSITY_WHEN_USED: aq.op.sum("WEIGHTED_DENSITY_WHEN_USED"),
        TOTAL_AREA_SQFT: aq.op.sum("AREA_SQFT"),
        TOTAL_SPACES: aq.op.count(),
        COST_PER_SQFT: aq.op.any("COST_PER_SQFT"),
      });

    const rows = result.objects();
    if (rows.length) return rows[0];
    return null;
  }, [table]);

  const { minDensity, medianDensity, maxDensity } = useContext(
    PlanContextualDataContext
  );
  const densityColorScale = useMemo(() => {
    return generateDensityColorScale(minDensity, medianDensity, maxDensity);
  }, [minDensity, medianDensity, maxDensity]);

  const timeUsedColorScale = useMemo(() => {
    return generateTimeUsedColorScale();
  }, []);

  return (
    <Box height="100%" sx={{ overflowY: "auto" }}>
      <Box sx={{ px: 2 }}>
        <Typography
          variant={"overline"}
          sx={{ color: dust.Gray400, lineHeight: 0 }}
        >
          Floor
        </Typography>
        <Typography variant={"h6"} sx={{ my: 0, py: 0 }}>
          {selectedFloor}
        </Typography>
      </Box>
      <Divider />
      {stats ? (
        <List dense sx={{ py: 0 }}>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <MetricDisplay label="Total Area">
              {`${formatAsInteger(stats.TOTAL_AREA_SQFT)} sqft`}
            </MetricDisplay>
            <MetricDisplay label="Total Spaces">
              {formatAsInteger(stats.TOTAL_SPACES)}
            </MetricDisplay>
          </Box>
          <ListItem sx={{ display: "block" }}>
            <LineItemGraphic
              label="Time Used"
              value={stats.WEIGHTED_TIME_USED_PERCENT / stats.TOTAL_AREA_SQFT}
              valueFormat={formatAsPercent}
              colorScale={timeUsedColorScale}
            />
          </ListItem>
          <ListItem sx={{ display: "block" }}>
            <LineItemGraphic
              label="Avg. Density"
              value={stats.WEIGHTED_DENSITY_WHEN_USED / stats.TOTAL_AREA_SQFT}
              valueFormat={formatAsTeamDensity}
              colorScale={densityColorScale}
            />
          </ListItem>
          <MetricDisplay label="Cost Per Sqft (annual)">
            {formatAsDollarsAndCents(plan.meta.COST_PER_SQFT)}
          </MetricDisplay>
          <MetricDisplay label="Total Cost (annual)">
            {formatAsDollars(plan.meta.COST_PER_SQFT * stats.TOTAL_AREA_SQFT)}
          </MetricDisplay>
        </List>
      ) : (
        <Box px={2}>-- no data --</Box>
      )}
    </Box>
  );
};
