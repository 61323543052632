import React, { useState } from "react";

import { Box } from "@mui/material";

import { CalendarView } from "app/calendar-view";
import { ChartView } from "app/chart-view";
import { MapView } from "app/map-view";
import { SettingsView } from "app/settings-view";
import { TopNavigation } from "components/top-navigation";
import { LeftMenuBar } from "components/side-navigation";
import { SpacePanel } from "components/space-panel";
import { MainToolbar } from "components/main-toolbar";
import { PlanContextualDataProvider } from "components/plan-contextual-data-provider";
import { ColorScaleProvider } from "components/color-scale-provider";
import { PlanLoader } from "components/plan-loader";
import { PlanSelectionLoader } from "components/plan-selection-loader";
import { OrganizationLoader } from "components/organization-loader";
import { DatabaseLoader } from "components/database-loader";
import { ParquetDataLoader } from "components/parquet-data-loader";
import { PlansLoader } from "components/plans-loader";
import { UserLoader } from "components/user-loader";
import { SpaceSelectionProvider } from "components/space-selection-provider";
import { ActiveView, Wrapper } from "lib/types";
import { BottomStatusBar } from "components/bottom-status-bar";
import { StoreProvider } from "components/store-provider";

const Root: Wrapper = (props) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        maxHeight: "100%",
        boxSizing: "border-box",
      }}
    >
      {props.children}
    </Box>
  );
};

const MainLayout: Wrapper = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexGrow={1}
      flexShrink={0}
      sx={{
        width: "100%",
        maxHeight: "100%",
      }}
    >
      {children}
    </Box>
  );
};

const MainPanel: Wrapper = ({ children }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", flex: 1, p: 0 }}>
      {children}
    </Box>
  );
};

export const App: React.FunctionComponent = () => {
  const [activeView, setActiveView] = useState<ActiveView>("map");

  return (
    <StoreProvider>
      <Root>
        <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
          <LeftMenuBar {...{ activeView, setActiveView }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              height: "100%",
            }}
          >
            <UserLoader>
              <DatabaseLoader>
                <ParquetDataLoader>
                  <PlansLoader>
                    <PlanSelectionLoader>
                      <SpaceSelectionProvider>
                        <TopNavigation />
                        <MainLayout>
                          <OrganizationLoader>
                            <PlanLoader>
                              <PlanContextualDataProvider>
                                <ColorScaleProvider>
                                  <MainPanel>
                                    <MainToolbar />
                                    {activeView === "map" ? (
                                      <MapView />
                                    ) : activeView === "chart" ? (
                                      <ChartView />
                                    ) : activeView === "calendar" ? (
                                      <CalendarView />
                                    ) : activeView === "settings" ? (
                                      <SettingsView />
                                    ) : null}
                                  </MainPanel>
                                  <SpacePanel />
                                </ColorScaleProvider>
                              </PlanContextualDataProvider>
                            </PlanLoader>
                          </OrganizationLoader>
                        </MainLayout>
                        <BottomStatusBar />
                      </SpaceSelectionProvider>
                    </PlanSelectionLoader>
                  </PlansLoader>
                </ParquetDataLoader>
              </DatabaseLoader>
            </UserLoader>
          </Box>
        </Box>
      </Root>
    </StoreProvider>
  );
};
