import React, { useContext, useState } from "react";
import { Box, styled, Typography } from "@mui/material";
import { format, startOfWeek, endOfWeek } from "date-fns";
import * as dust from "@density/dust/dist/tokens/dust.tokens";
import { Icons } from "@density/dust";

import { useAppStore } from "app/hooks/use-app-store";
import { MonthId, parseMonthId, parseWeekId, WeekId } from "app/state/calendar";
import { formatSpaceFunction } from "app/state/space-function";
import { PlanSelectionContext } from "lib/contexts";
import { ChosenMetric } from "lib/types";

const SpaceType = styled("span")({
  color: dust.Purple400,
});

const MetricType = styled("span")({
  color: dust.Blue400,
});

const TimeframeWorkingHours = styled("span")({
  color: dust.Orange400,
});

const Timeframe = styled("span")({
  color: dust.Teal400,
});

const Muted = styled("span")({
  color: dust.Gray300,
});

export const BottomStatusBar: React.FunctionComponent = () => {
  const [visible, setVisible] = useState(true);

  const [{ colorBy }] = useContext(PlanSelectionContext);

  const { calendar, spaceFunction } = useAppStore();
  const { selected: selectedSpaceFunctions } = spaceFunction;
  const { timeframe } = calendar;

  const spacesFilterText =
    selectedSpaceFunctions.size > 0 ? (
      <span>
        spaces of type{" "}
        {Array.from(selectedSpaceFunctions).map((f, i) => (
          <React.Fragment key={f}>
            <SpaceType>{formatSpaceFunction(f)}</SpaceType>
            {i < selectedSpaceFunctions.size - 1 ? <span> or </span> : null}
          </React.Fragment>
        ))}
      </span>
    ) : (
      <SpaceType>all spaces</SpaceType>
    );

  const timeframeText =
    timeframe.type === "month" ? (
      <span>
        for the{" "}
        <Timeframe>
          month of {formatTimeframeMonth(timeframe.monthId)}
        </Timeframe>
      </span>
    ) : timeframe.type === "week" ? (
      <span>
        for the <Timeframe>{formatTimeframeWeek(timeframe.weekId)}</Timeframe>
      </span>
    ) : null;

  const statusText = (
    <span>
      <span>Showing</span> <MetricType>{formatMetric(colorBy)}</MetricType>
      <br />
      <span>for</span> {spacesFilterText}
      <br />
      <span>during</span>{" "}
      <TimeframeWorkingHours>working hours</TimeframeWorkingHours>{" "}
      <Muted>(M-F 8a-6p)</Muted>
      <br />
      {timeframeText}
    </span>
  );

  return (
    <Box
      position="relative"
      height={20}
      width="100%"
      display="flex"
      flexDirection="row"
      alignItems="center"
      fontSize={12}
      px={1}
      sx={{ borderTop: `1px solid ${dust.Gray200}`, cursor: "pointer" }}
      onClick={() => setVisible((v) => !v)}
    >
      {visible ? (
        <Icons.VisibilityShowEye color={dust.Gray600} size={16} />
      ) : (
        <Icons.VisibilityHideEye color={dust.Gray300} size={16} />
      )}

      {visible ? (
        <Box
          position="absolute"
          bottom={20}
          left={0}
          width={240}
          maxHeight={140}
          overflow="auto"
          p={1}
        >
          <Typography
            fontSize={10}
            fontFamily="Aeonikmono"
            fontWeight={400}
            lineHeight={1.25}
          >
            {statusText}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

function formatTimeframeMonth(monthId: MonthId) {
  const date = parseMonthId(monthId);
  return format(date, "MMMM yyyy");
}

function formatTimeframeWeek(weekId: WeekId) {
  const date = parseWeekId(weekId);
  const weekStart = startOfWeek(date);
  const weekEnd = endOfWeek(date);
  return `week of ${format(weekStart, "MMM d")} to ${format(weekEnd, "MMM d")}`;
}

function formatMetric(metric: ChosenMetric) {
  return metric === "density" ? "Density" : "Time Used";
}
