import { useMemo, useRef } from "react";
import { Box, Typography } from "@mui/material";
import * as d3 from "d3";
import useSize from "@react-hook/size";
import * as colors from "@density/proto-ui-colors";

import { MapColorScale } from "lib/types";
import { ScaleLinear } from "d3";

export const LineItemGraphic: React.FunctionComponent<{
  label: string;
  value: number | null;
  colorScale: MapColorScale;
  lengthScale?: ScaleLinear<number, number>;
  valueFormat?: (value: number) => string;
}> = ({
  label,
  value,
  colorScale,
  lengthScale,
  valueFormat = (v) => v.toLocaleString("en-US", { style: "percent" }),
}) => {
  const containerElementRef = useRef<HTMLElement>(null);
  // const axisXElementRef = useRef<SVGGElement>(null);
  const [width, height] = useSize(containerElementRef);

  const scaleX = useMemo(() => {
    if (lengthScale) return lengthScale;
    return d3.scaleLinear().domain([0, 1]).range([0, width]);
  }, [lengthScale, width]);

  return (
    <Box display={"flex"} flexDirection={"column"} sx={{ my: 0.5, p: 0 }}>
      <Box
        flex={1}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"flex-end"}
      >
        <Typography variant="body1" align="left">
          {value ? valueFormat(value) : "--"}
        </Typography>
        <Typography variant="caption" align="right" sx={{ flex: 1 }}>
          {label}
        </Typography>
      </Box>
      <Box
        width={"100%"}
        height={10}
        ref={containerElementRef}
        display={"block"}
      >
        <svg width={width} height={height} display={"block"}>
          <rect
            width={width}
            height={height}
            fill={colors.Gray100}
            rx={2}
            ry={2}
          />
          <rect
            x={scaleX(0)}
            y={0}
            width={scaleX(value ? value : 0)}
            height={height}
            fill={colorScale(value ? value : 0)}
            fillOpacity={0.8}
            rx={2}
            ry={2}
          />

          {/* <g ref={axisXElementRef} /> */}
        </svg>
      </Box>
    </Box>
  );
};
