import { useContext, useEffect } from "react";
import * as aq from "arquero";

import { DatabaseContext, PortfolioUsageTableContext } from "lib/contexts";
import { useResolvable, Resolvable } from "lib/resolvable";
import { Wrapper } from "lib/types";
import { ErrorState } from "components/error-state";
import { Loader } from "components/loader";
import { Resolve } from "components/resolve";

export const OccupancyLoader: Wrapper<{
  organizationName: string;
}> = ({ organizationName, children }) => {
  const db = useContext(DatabaseContext);
  const [state, setState] = useResolvable<aq.internal.ColumnTable>();

  useEffect(() => {
    setState(Resolvable.updateRunning());
    (async () => {
      const conn = await db.connect();

      await conn.query(`
        CREATE OR REPLACE TABLE occupancy_time_used_agg_date AS (
          SELECT *
          FROM 'occupancy_time_used_agg_date.parquet'
        )
      `);
      await conn.query(`
        CREATE INDEX occ_space_idx ON occupancy_time_used_agg_date (SPACE_ID)
      `);
      await conn.query(`
        CREATE INDEX occ_date_idx ON occupancy_time_used_agg_date (LOCAL_DATE)
      `);

      await conn.query(`
        CREATE OR REPLACE TABLE count_frequency_by_space_by_day AS (
          SELECT *
          FROM 'count_frequency_by_space_by_day.parquet'
        )
      `);

      await conn.query(`
        CREATE INDEX count_freq_space_idx ON count_frequency_by_space_by_day (SPACE_ID)
      `);
      await conn.query(`
        CREATE INDEX count_freq_date_idx ON count_frequency_by_space_by_day (LOCAL_DATE)
      `);

      setState(Resolvable.updateRunning());

      // const result = await conn.query(`SELECT * FROM agg_density_filtered`);
      const result = await conn.query(`
          SELECT
            p.ORGANIZATION_NAME,
            p.BUILDING_NAME,
            p.FLOOR_NAME,
            p.PLAN_ID,
            s.SPACE_ID,
            s.SPACE_NAME,
            s.AREA_SQFT,
            s.FUNCTION,
            s.CAPACITY,
            s.LABELS,
            p.COST_PER_SQFT,
            strftime(LOCAL_DATE, '%Y-%m-%d') AS LOCAL_DATE,
            TIME_USED_PERCENT,
            AVG_OCCUPANCY_WHEN_USED,
            (AREA_SQFT / AVG_OCCUPANCY_WHEN_USED) AS AVG_TEAM_DENSITY_WHEN_USED
          FROM occupancy_time_used_agg_date o
          INNER JOIN spaces s
          ON s.SPACE_ID = o.SPACE_ID
          INNER JOIN plans p
          ON p.PLAN_ID = s.PLAN_ID
          WHERE p.ORGANIZATION_NAME = '${organizationName}'
      `);

      await conn.close();
      setState(Resolvable.completeWith(aq.fromArrow(result)));
    })();
  }, [db, setState, organizationName]);

  return (
    <Resolve
      resolvable={state}
      onRunning={() => <Loader>Loading occupancy data...</Loader>}
      onFailed={(error) => {
        console.error(error);
        return <ErrorState>Error loading occupancy data.</ErrorState>;
      }}
      onCompleted={(table) => {
        return (
          <PortfolioUsageTableContext.Provider value={{ table }}>
            {children}
          </PortfolioUsageTableContext.Provider>
        );
      }}
    />
  );
};
