import { useState, useCallback } from "react";

import { TokenProvider } from "@densityco/lib-common-auth";
import { Error403 } from "app/pages/error-403";
import { UserContext } from "lib/contexts";
import { Wrapper, PrototypeUser } from "lib/types";

const USER_STORAGE_KEY = "io.density.prototype.user";
const TOKEN_STORAGE_KEY = "io.density.prototype.token";

export const UserLoader: Wrapper = (props) => {
  const [user, setUser] = useState<PrototypeUser>(() => {
    const _userFromStorage = localStorage.getItem(USER_STORAGE_KEY);
    const defaultUser: PrototypeUser = {
      mode: "superuser",
    };
    const initialUser: PrototypeUser = _userFromStorage
      ? JSON.parse(_userFromStorage)
      : defaultUser;
    return initialUser;
  });

  const setAndStoreUser = useCallback<(user: PrototypeUser) => void>(
    (user) => {
      setUser(user);
      localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    },
    [setUser]
  );

  return (
    <TokenProvider
      loginHost={"https://api.density.io"}
      tokenLocalStorageKey={TOKEN_STORAGE_KEY}
    >
      {(data) =>
        data.tokenCheckResponse?.permissions?.includes("impersonate") ? (
          <UserContext.Provider value={[user, setAndStoreUser]}>
            {props.children}
          </UserContext.Provider>
        ) : (
          <Error403 />
        )
      }
    </TokenProvider>
  );
};
