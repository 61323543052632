import { useContext } from "react";

import { PlanSelectionContext } from "lib/contexts";
import { Wrapper } from "lib/types";
import { OccupancyLoader } from "components/occupancy-loader";
import { SpacesLoader } from "./spaces-loader";

export const OrganizationLoader: Wrapper = ({ children }) => {
  const [planSelectionState] = useContext(PlanSelectionContext);
  const { selectedOrg } = planSelectionState;

  return (
    <OccupancyLoader organizationName={selectedOrg}>
      <SpacesLoader organizationName={selectedOrg}>{children}</SpacesLoader>
    </OccupancyLoader>
  );
};
