import React, { useContext, useEffect, useMemo, useState } from "react";
import * as arrow from "apache-arrow";
import * as aq from "arquero";
import ColumnTable from "arquero/dist/types/table/column-table";

import { FloorplanDisplay } from "components/floorplan-display";
import {
  getFocusedSpaceId,
  SpaceSelectionContext,
} from "app/state/space-selection";

import {
  DatabaseContext,
  PlanContextualDataContext,
  PlanSelectionContext,
  PortfolioUsageTableContext,
} from "lib/contexts";
import { Resolvable } from "lib/resolvable";
import { getPlanSpaces } from "lib/query";
import { Loader } from "components/loader";
import { CalendarColorData, SpacesTableRow } from "lib/types";
import { ScopeToggle } from "components/scope-toggle";
import { Box } from "@mui/material";
import { CalendarWidget } from "components/calendar-widget";
import {
  filterTableByCalendarSelection,
  filterTableByPlan,
  filterTableBySpaceSelection,
  OccupancyDataRow,
} from "lib/occupancy";
import { Params, Struct } from "arquero/dist/types/table/transformable";
import {
  generateDensityColorScale,
  generateTimeUsedColorScale,
} from "lib/color-scale";
import { useAppStore } from "./hooks/use-app-store";
import { useAppDispatch } from "./hooks/use-app-dispatch";
import { DateString, DayName } from "./state/calendar";

export const MapView: React.FunctionComponent = () => {
  const db = useContext(DatabaseContext);
  const [planSelectionState, planSelectionDispatch] =
    useContext(PlanSelectionContext);

  const { selectedPlanId, colorBy } = planSelectionState;

  const [spaceSelectionState, spaceSelectionActions] = useContext(
    SpaceSelectionContext
  );

  const focusedSpaceId = useMemo(() => {
    return getFocusedSpaceId(spaceSelectionState);
  }, [spaceSelectionState]);

  const [state, setState] = useState<Resolvable<QueryResults>>({
    status: Resolvable.Status.NONE,
  });

  useEffect(() => {
    setState(Resolvable.updateRunning());
    (async () => {
      const spaces = await getPlanSpaces(db, selectedPlanId);
      setState(Resolvable.completeWith({ planSpaces: spaces }));
    })();
  }, [db, selectedPlanId]);

  const contextualData = useContext(PlanContextualDataContext);
  const densityColorScale = useMemo(() => {
    const { minDensity, medianDensity, maxDensity } = contextualData;
    return generateDensityColorScale(minDensity, medianDensity, maxDensity);
  }, [contextualData]);
  const timeUsedColorScale = useMemo(() => {
    return generateTimeUsedColorScale();
  }, []);

  const storeValue = useAppStore();
  const dispatch = useAppDispatch();

  const calendarState = storeValue.calendar;
  const spaceFunctionState = storeValue.spaceFunction;

  const { table } = useContext(PortfolioUsageTableContext);

  const planDataByDate = useMemo(() => {
    return filterTableByPlan(table, selectedPlanId);
  }, [table, selectedPlanId]);

  const filteredTable = useMemo(() => {
    return filterTableBySpaceSelection(
      filterTableByPlan(table, selectedPlanId),
      spaceFunctionState.selected
    );
  }, [table, selectedPlanId, spaceFunctionState.selected]);

  const colorData = useMemo(() => {
    // if selected on a Space, show data by date for Space

    if (focusedSpaceId) {
      const extract =
        colorBy === "density"
          ? (datum: OccupancyDataRow) => {
              return densityColorScale(datum.AVG_TEAM_DENSITY_WHEN_USED);
            }
          : (datum: OccupancyDataRow) => {
              return timeUsedColorScale(datum.TIME_USED_PERCENT);
            };
      return (planDataByDate as ColumnTable)
        .params({ focusedSpaceId })
        .filter((d: Struct, $: Params) => {
          return d.SPACE_ID === $.focusedSpaceId;
        })
        .objects()
        .reduce((dataByDate: CalendarColorData, datum: OccupancyDataRow) => {
          dataByDate[datum.LOCAL_DATE] = extract(datum);
          return dataByDate;
        }, {} as CalendarColorData);

      // otherwise show data by date for Plan (aggregated)
    } else {
      const extract =
        colorBy === "density"
          ? (datum: any) => {
              return densityColorScale(datum.avgDensityWhenUsed);
            }
          : (datum: any) => {
              return timeUsedColorScale(datum.timeUsedPercent);
            };
      return filteredTable
        .groupby("LOCAL_DATE")
        .derive({
          weightedTimeUsed: (d: Struct) => d.TIME_USED_PERCENT * d.AREA_SQFT,
          weightedAvgDensityWhenUsed: (d: Struct) =>
            d.AVG_TEAM_DENSITY_WHEN_USED * d.AREA_SQFT,
        })
        .rollup({
          // weighted avg time used by space size
          timeUsedPercent: (d: Struct) =>
            aq.op.sum(d.weightedTimeUsed) / aq.op.sum(d.AREA_SQFT),
          avgDensityWhenUsed: (d: Struct) =>
            aq.op.sum(d.weightedAvgDensityWhenUsed) / aq.op.sum(d.AREA_SQFT),
        })
        .objects()
        .reduce((dataByDate: CalendarColorData, datum: any) => {
          dataByDate[datum.LOCAL_DATE] = extract(datum);
          return dataByDate;
        }, {} as CalendarColorData);
    }
  }, [
    planDataByDate,
    filteredTable,
    focusedSpaceId,
    colorBy,
    densityColorScale,
    timeUsedColorScale,
  ]);

  const planDataAggregated = useMemo(() => {
    const filt = filterTableByCalendarSelection(planDataByDate, calendarState);
    return filt
      .groupby("SPACE_ID")
      .rollup({
        spaceId: (d: Struct) => aq.op.any(d.SPACE_ID),
        timeUsedPercent: (d: Struct) => aq.op.mean(d.TIME_USED_PERCENT),
        avgOccupancyWhenUsed: (d: Struct) =>
          aq.op.mean(d.AVG_OCCUPANCY_WHEN_USED),
        avgDensityWhenUsed: (d: Struct) =>
          aq.op.mean(d.AVG_TEAM_DENSITY_WHEN_USED),
      })
      .objects()
      .reduce((data: any, datum: any) => {
        data[datum.spaceId] = datum;
        return data;
      }, {} as any);
  }, [planDataByDate, calendarState]);

  return state.status === Resolvable.Status.COMPLETED ? (
    <Box display="flex" flexDirection="row" flex={1} height="100%" pl={1}>
      <CalendarWidget
        colorData={colorData}
        shownInterval={{
          start: "2022-10-01",
          end: "2023-01-31",
        }}
        dateSelection={calendarState}
        onDayClick={(date: DateString) => {
          dispatch({ type: "calendar.disabledDates.toggle", date });
        }}
        onDayOfWeekClick={(dayName: DayName) => {
          dispatch({ type: "calendar.disabledDayNames.toggle", dayName });
        }}
        onTimeframeChange={(timeframe) => {
          dispatch({ type: "calendar.timeframe.selected", timeframe });
        }}
      />
      <Box display="flex" flexDirection="column" flex={1} sx={{ p: 1 }}>
        <FloorplanDisplay
          planSpaces={state.value.planSpaces}
          planDataAggregated={planDataAggregated}
          contextualData={contextualData}
          focusedSpaceId={focusedSpaceId}
          onMouseEnterArea={spaceSelectionActions.onAreaMouseEnter}
          onMouseLeaveArea={spaceSelectionActions.onAreaMouseLeave}
          onClickArea={spaceSelectionActions.onAreaClick}
          onClickBackground={spaceSelectionActions.onBackgroundClick}
          colorBy={colorBy}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          sx={{ mb: 1 }}
        >
          <ScopeToggle
            value={planSelectionState.visualizationScope}
            onChange={(scope) =>
              planSelectionDispatch({
                type: "select-visualization-scope",
                scope,
              })
            }
          />
        </Box>
      </Box>
    </Box>
  ) : (
    <Loader>Loading plan data...</Loader>
  );
};

type FloorplanSpacesQueryResult = arrow.Table<{
  SPACE_ID: arrow.Utf8;
  CAPACITY: arrow.Uint32;
  FUNCTION: arrow.Utf8;
  AREA_SQFT: arrow.Float32;
  AREA_SHAPE: SpacesTableRow["AREA_SHAPE"];
  AREA_SHAPE_CIRCLE: SpacesTableRow["AREA_SHAPE_CIRCLE"];
  AREA_SHAPE_POLYGON: SpacesTableRow["AREA_SHAPE_POLYGON"];
  IMAGE_PIXELS_PER_METER: arrow.Float32;
}>;

type QueryResults = {
  planSpaces: FloorplanSpacesQueryResult;
};
