import React, { useContext, useMemo } from "react";
import { Box, Chip, Divider, List, ListItem, Typography } from "@mui/material";
import * as dust from "@density/dust/dist/tokens/dust.tokens";

import { Nullable, SpaceMeta, SpaceUsageData } from "lib/types";
import {
  formatAsDollars,
  formatAsDollarsAndCents,
  formatAsInteger,
  formatAsOccupancy,
  formatAsTeamDensity,
  isNumber,
} from "lib/formatters";
import { CountFrequencyGraphic } from "components/count-frequency-graphic";
import { LineItemGraphic } from "components/line-item-graphic";

import { MetricDisplay } from "./metric-display";
import {
  generateDensityColorScale,
  generateTimeUsedColorScale,
} from "lib/color-scale";
import { PlanContextualDataContext } from "lib/contexts";

type SpaceMetrics = {
  areaSqft: number;
  capacity: number;
  costPerSqft: number;
  costAnnual: number;
  avgOccupancyWhenUsed: number;
  avgDensityWhenUsed: number;
  timeUsedPercent: number;
};

export const FocusedSpaceDetail: React.FunctionComponent<{
  focusedSpaceId: string;
  spaceMeta?: SpaceMeta;
  spaceUsageData?: SpaceUsageData;
}> = ({ focusedSpaceId, spaceMeta, spaceUsageData }) => {
  const metrics: Partial<Nullable<SpaceMetrics>> | null = useMemo(() => {
    const areaSqft = spaceMeta?.areaSqft;
    const capacity = spaceMeta?.capacity;
    const costPerSqft = spaceMeta?.costPerSqft;
    const costAnnual =
      isNumber(areaSqft) && isNumber(costPerSqft)
        ? areaSqft * costPerSqft
        : null;
    const avgOccupancyWhenUsed = spaceUsageData?.avgOccupancyWhenUsed;

    const timeUsedPercent = spaceUsageData?.timeUsedPercent;

    return {
      areaSqft,
      capacity,
      costPerSqft,
      costAnnual,
      avgOccupancyWhenUsed,
      timeUsedPercent,
    };
  }, [spaceMeta, spaceUsageData]);

  const timeUsedColorScale = useMemo(() => {
    return generateTimeUsedColorScale();
  }, []);

  const { minDensity, medianDensity, maxDensity } = useContext(
    PlanContextualDataContext
  );
  const densityColorScale = useMemo(() => {
    return generateDensityColorScale(minDensity, medianDensity, maxDensity);
  }, [minDensity, medianDensity, maxDensity]);

  return (
    <Box flex={1}>
      <Box sx={{ px: 2 }}>
        <Typography
          variant={"overline"}
          sx={{ color: dust.Gray400, lineHeight: 0 }}
        >
          {spaceMeta?.spaceFunction?.replaceAll("_", " ") || "--"}
        </Typography>
        <Typography variant={"h6"} sx={{ my: 0, py: 0 }}>
          {spaceMeta?.name}
        </Typography>
      </Box>
      <Divider />
      <List dense sx={{ py: 0 }}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <MetricDisplay label="Area">
            {`${formatAsInteger(metrics.areaSqft)} sqft`}
          </MetricDisplay>
          <MetricDisplay label="Capacity">
            {formatAsInteger(metrics.capacity)}
          </MetricDisplay>
        </Box>
        <MetricDisplay label="Count Frequency">
          <CountFrequencyGraphic spaceId={focusedSpaceId} />
        </MetricDisplay>
        <ListItem sx={{ display: "block" }}>
          <LineItemGraphic
            label="Time Used"
            value={metrics.timeUsedPercent || 0}
            colorScale={timeUsedColorScale}
          />
        </ListItem>
        <ListItem sx={{ display: "block" }}>
          <LineItemGraphic
            label="Avg. Density"
            value={
              metrics.avgOccupancyWhenUsed && metrics.areaSqft
                ? metrics.areaSqft / metrics.avgOccupancyWhenUsed
                : null
            }
            valueFormat={formatAsTeamDensity}
            colorScale={densityColorScale}
          />
        </ListItem>
        {/* <MetricDisplay label="Target Density">
              {`${formatAsInteger(metrics.targetDensity)} sqft / person`}
            </MetricDisplay> */}
        <MetricDisplay label="Avg. Occupancy (when used)">
          {formatAsOccupancy(metrics.avgOccupancyWhenUsed)}
        </MetricDisplay>
        <MetricDisplay label="Cost Per Sqft (annual)">
          {formatAsDollarsAndCents(metrics.costPerSqft)}
        </MetricDisplay>
        <MetricDisplay label="Cost (annual)">
          {formatAsDollars(metrics.costAnnual)}
        </MetricDisplay>

        <MetricDisplay label="Labels">
          {spaceMeta?.labels.map((label) => {
            return (
              <Chip
                key={label.id}
                size="small"
                label={label.name}
                sx={{ fontSize: 10, height: 16, mx: 0.25 }}
              />
            );
          })}
        </MetricDisplay>
      </List>
    </Box>
  );
};
