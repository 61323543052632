import React, { useCallback, useContext, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Link,
  MenuItem,
  MenuList,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import * as dust from "@density/dust/dist/tokens/dust.tokens";

import { PlanSelectionContext } from "lib/contexts";
import { UserMenu } from "components/user-menu";
// import { DateRangeSelect } from "./date-range-picker";

type QuickToggleMenuLinkProps<T> = {
  items: Array<T>;
  value: T;
  onSelectItem: (item: T) => void;
  extractKey?: (item: T) => string;
  extractLabel?: (item: T) => string;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    fontSize: 11,
  },
}));

const QuickToggleMenuLink = <T extends unknown>({
  items,
  value,
  onSelectItem,
  extractKey = (thing: T) => String(thing),
  extractLabel = (thing: T) => String(thing),
}: QuickToggleMenuLinkProps<T>) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <Box>
      <LightTooltip
        PopperProps={{
          sx: {
            maxWidth: "none",
            overflowY: "auto",
          },
        }}
        placement={"bottom-start"}
        title={
          <MenuList
            dense
            sx={{
              maxWidth: "100%",
              columnCount: items.length < 10 ? 1 : items.length < 20 ? 2 : 3,
            }}
          >
            {items.map((item) => {
              return (
                <MenuItem
                  key={extractKey(item)}
                  onClick={() => {
                    onSelectItem(item);
                    handleClose();
                  }}
                >
                  {extractLabel(item)}
                </MenuItem>
              );
            })}
          </MenuList>
        }
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      >
        <Link>{extractLabel(value)}</Link>
      </LightTooltip>
    </Box>
  );
};

export const TopNavigation: React.FunctionComponent = () => {
  const [planSelectionState, planSelectionDispatch] =
    useContext(PlanSelectionContext);
  const {
    orgs,
    buildings,
    floors,
    selectedOrg,
    selectedBuilding,
    selectedFloor,
  } = planSelectionState;

  const handleOrgMenuSelect = useCallback(
    (value: string) => {
      planSelectionDispatch({ type: "select-org", org: value });
    },
    [planSelectionDispatch]
  );
  const handleBuildingMenuSelect = useCallback(
    (value: string) => {
      planSelectionDispatch({ type: "select-building", building: value });
    },
    [planSelectionDispatch]
  );
  const handleFloorMenuSelect = useCallback(
    (value: string) => {
      planSelectionDispatch({ type: "select-floor", floor: value });
    },
    [planSelectionDispatch]
  );
  return (
    <Box
      flexShrink={0}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: 40,
        p: 1,
        px: 2,
        borderBottom: `1px solid ${dust.Gray100}`,
      }}
    >
      <Box display={"flex"} flexGrow={1} alignItems={"center"}>
        <Breadcrumbs>
          <QuickToggleMenuLink
            value={selectedOrg}
            items={orgs}
            onSelectItem={handleOrgMenuSelect}
          />
          <QuickToggleMenuLink
            value={selectedBuilding}
            items={buildings}
            onSelectItem={handleBuildingMenuSelect}
          />
          <QuickToggleMenuLink
            value={selectedFloor}
            items={floors}
            onSelectItem={handleFloorMenuSelect}
          />
        </Breadcrumbs>
      </Box>

      <UserMenu />
    </Box>
  );
};
