import { useContext, useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import * as arrow from "apache-arrow";
import * as aq from "arquero";
import * as dust from "@density/dust/dist/tokens/dust.tokens";

import { Box } from "@mui/material";
import useSize from "@react-hook/size";

import { PlanContext } from "lib/contexts";
import { ChosenMetric, PlanContextualData } from "lib/types";
import {
  createCircleFeature,
  createPolygonFeature,
} from "lib/feature-collection";
import {
  SpaceSelectionContext,
  SpaceSelectionState,
} from "app/state/space-selection";
import {
  generateDensityColorScale,
  generateTimeUsedColorScale,
} from "lib/color-scale";
import { useAppStore } from "app/hooks/use-app-store";

const projection = d3.geoIdentity();
const pathGenerator = d3.geoPath(projection);

const legendHeight = 80;
const legendAxisYOffset = 40;
const legendPadding = {
  left: 20,
  right: 20,
};

type PlanDataAggregated = {
  [spaceId: string]: {
    spaceId: string;
    timeUsedPercent: number;
    avgOccupancyWhenUsed: number;
    avgDensityWhenUsed: number;
  };
};

export const FloorplanDisplay: React.FunctionComponent<{
  planSpaces: arrow.Table;
  planDataAggregated: PlanDataAggregated;
  contextualData: PlanContextualData;
  focusedSpaceId: string | null;
  onMouseEnterArea: (spaceId: string) => void;
  onMouseLeaveArea: (spaceId: string) => void;
  onClickArea: (spaceId: string) => void;
  onClickBackground: () => void;
  colorBy: ChosenMetric;
}> = ({
  planSpaces,
  planDataAggregated,
  contextualData,
  focusedSpaceId,
  onMouseEnterArea,
  onMouseLeaveArea,
  onClickArea,
  onClickBackground,
  colorBy,
}) => {
  const wrapperElementRef = useRef<HTMLElement>(null);
  const [width, height] = useSize(wrapperElementRef);
  const { spaceFunction: spaceFunctionState } = useAppStore();

  const legendWidth = useMemo(() => {
    return Math.min(800, width);
  }, [width]);

  const table = useMemo(() => {
    let table = aq.fromArrow(planSpaces);
    table = table
      .params({
        colorBy,
        spaceFunctions: spaceFunctionState.selected,
      })
      // @ts-ignore
      .filter((d, $) => {
        if ($.colorBy === "density" && d.CAPACITY === 1) return false;
        if ($.spaceFunctions.size === 0) return true;
        return aq.op.has($.spaceFunctions, d.FUNCTION);
      });

    return table;
  }, [planSpaces, colorBy, spaceFunctionState]);

  const { plan } = useContext(PlanContext);

  const [spaceSelectionState] = useContext(SpaceSelectionContext);

  const densityColorScale = useMemo(() => {
    const { minDensity, medianDensity, maxDensity } = contextualData;
    return generateDensityColorScale(minDensity, medianDensity, maxDensity);
  }, [contextualData]);

  const timeUsedColorScale = useMemo(() => {
    return generateTimeUsedColorScale();
  }, []);

  const areaBounds = useMemo(() => {
    let minX = Infinity;
    let maxX = 0;
    let minY = Infinity;
    let maxY = 0;
    for (const feature of plan.areaFeatureCollection.features) {
      if (feature.geometry.type === "Polygon") {
        for (const coord of feature.geometry.coordinates[0]) {
          minX = Math.min(minX, coord[0]);
          maxX = Math.max(maxX, coord[0]);
          minY = Math.min(minY, coord[1]);
          maxY = Math.max(maxY, coord[1]);
        }
      }
    }
    return {
      x: minX - 40,
      y: minY - 100,
      width: maxX - minX + 80,
      height: maxY - minY + 200,
    };
  }, [plan.areaFeatureCollection]);

  type Row = {
    SPACE_ID: string;
  } & (
    | {
        AREA_SHAPE: "circle";
        AREA_SHAPE_CIRCLE: { x: number; y: number; radius: number };
        AREA_SHAPE_POLYGON: null;
      }
    | {
        AREA_SHAPE: "polygon";
        AREA_SHAPE_CIRCLE: null;
        AREA_SHAPE_POLYGON: Array<{ x: number; y: number }>;
      }
  );

  const planSpacesSorted: Array<Row> = useMemo(() => {
    return table.orderby(aq.desc("AREA_SQFT")).objects() as Array<Row>;
  }, [table]);

  return (
    <Box
      display="block"
      position="relative"
      flex={1}
      minWidth={0}
      minHeight={0}
      boxSizing="border-box"
      ref={wrapperElementRef}
      onClick={onClickBackground}
    >
      <svg
        width={width}
        height={Math.max(0, height - legendHeight)}
        viewBox={
          // ok, this is pretty bad...
          plan.meta.BUILDING_NAME === "Bellevue"
            ? `0 0 ${plan.meta.IMAGE_WIDTH} ${plan.meta.IMAGE_HEIGHT}`
            : `${areaBounds.x} ${areaBounds.y} ${areaBounds.width} ${areaBounds.height}`
        }
        style={{
          position: "absolute",
          display: "block",
          width: width,
          height: height - legendHeight,
          maxWidth: "100%",
          maxHeight: `calc(100% - ${legendHeight}px)`,
        }}
      >
        <filter id="grayscale">
          <feColorMatrix type="saturate" values="0" />
        </filter>

        <image opacity={0.8} filter="url(#grayscale)" href={plan.imageUrl} />

        {planSpacesSorted.map((space) => {
          const isFocused = space.SPACE_ID === focusedSpaceId;

          const selectionExists =
            spaceSelectionState.status === "selection-only" ||
            spaceSelectionState.status === "selection-and-peek";

          const focusExists = spaceSelectionState.status !== "no-selection";

          const isSelected =
            selectionExists &&
            space.SPACE_ID === spaceSelectionState.selectedSpaceId;

          const dataForSpace = planDataAggregated[space.SPACE_ID];
          const hasSpaceData = Boolean(dataForSpace);

          const color =
            (hasSpaceData
              ? colorBy === "density"
                ? densityColorScale(dataForSpace.avgDensityWhenUsed)
                : timeUsedColorScale(dataForSpace.timeUsedPercent)
              : dust.Gray300) || dust.Gray300;

          const spaceId = space.SPACE_ID;

          let feature;
          if (space.AREA_SHAPE === "circle") {
            const { x, y, radius } = space.AREA_SHAPE_CIRCLE;
            feature = createCircleFeature(
              x,
              y,
              radius,
              plan.meta.IMAGE_PIXELS_PER_METER
            );
          } else {
            feature = createPolygonFeature(
              space.AREA_SHAPE_POLYGON,
              plan.meta.IMAGE_PIXELS_PER_METER
            );
          }

          return (
            <g key={spaceId}>
              <path
                d={pathGenerator(feature)!}
                fill={color}
                fillOpacity={
                  isFocused || isSelected ? 0.9 : focusExists ? 0.3 : 0.75
                }
                stroke={isFocused || isSelected ? dust.Gray900 : color}
                strokeWidth={isSelected ? "2px" : isFocused ? "1px" : 0}
                vectorEffect={"non-scaling-stroke"}
                strokeLinejoin={"round"}
                strokeLinecap={"round"}
                onClick={(evt) => {
                  evt.stopPropagation();
                  onClickArea(spaceId);
                }}
                onMouseEnter={() => onMouseEnterArea(spaceId)}
                onMouseLeave={() => {
                  onMouseLeaveArea(spaceId);
                }}
                cursor={"pointer"}
                // style={{ transition: "fill-opacity 100ms ease" }}
              />
            </g>
          );
        })}
      </svg>
      <Legend
        {...{
          width,
          legendWidth,
          colorBy,
          densityColorScale,
          timeUsedColorScale,
          contextualData,
          planDataAggregated,
          planSpacesSorted,
          focusedSpaceId,
          spaceSelectionState,
          onMouseEnterArea,
          onMouseLeaveArea,
          onClickArea,
          onClickBackground,
        }}
      />
    </Box>
  );
};

const Legend: React.FunctionComponent<{
  width: number;
  legendWidth: number;
  colorBy: ChosenMetric;
  densityColorScale: d3.ScaleLinear<string, string>;
  timeUsedColorScale: d3.ScaleLinear<string, string>;
  contextualData: PlanContextualData;
  planDataAggregated: PlanDataAggregated;
  planSpacesSorted: Array<{ SPACE_ID: string }>;
  focusedSpaceId: string | null;
  spaceSelectionState: SpaceSelectionState;
  onMouseEnterArea: (spaceId: string) => void;
  onMouseLeaveArea: (spaceId: string) => void;
  onClickArea: (spaceId: string) => void;
  onClickBackground: () => void;
}> = ({
  width,
  legendWidth,
  colorBy,
  densityColorScale,
  timeUsedColorScale,
  contextualData,
  planDataAggregated,
  planSpacesSorted,
  focusedSpaceId,
  spaceSelectionState,
  onMouseEnterArea,
  onMouseLeaveArea,
  onClickArea,
  onClickBackground,
}) => {
  const legendAxisGroupElementRef = useRef<SVGGElement>(null);

  const scaleExtent = useMemo(() => {
    if (colorBy === "density") {
      const { minDensity, maxDensity } = contextualData;
      return [minDensity, maxDensity];
    }
    return [0, 1];
  }, [contextualData, colorBy]);

  const legendXScale = useMemo(() => {
    const scale = d3
      .scaleLinear()
      .domain(scaleExtent)
      .range([legendPadding.left, legendWidth - legendPadding.right])
      .nice();

    return scale;
  }, [scaleExtent, legendWidth]);

  // Legend Axis
  useEffect(() => {
    const group = legendAxisGroupElementRef.current;
    if (!group) return;
    const axis = d3.axisBottom(legendXScale).ticks(10);
    if (colorBy === "time-used") {
      axis.tickFormat(d3.format("~%"));
    }
    const sel = d3.select(group);
    sel.call(axis);
    sel.selectAll("line,path").attr("stroke", dust.Gray300);
    sel
      .selectAll("text")
      .attr("font-family", "Aeonik")
      .attr("fill", dust.Gray900);
  }, [legendXScale, colorBy]);

  return (
    <svg
      style={{
        position: "absolute",
        bottom: 0,
        overflow: "visible",
        marginLeft: (width - legendWidth) / 2,
        marginRight: (width - legendWidth) / 2,
      }}
      width={legendWidth}
      height={legendHeight}
      viewBox={`0 0 ${legendWidth} ${legendHeight}`}
    >
      {/* TODO: Show distribution when viewing Density metric */}

      {/* Axis */}
      <g
        transform={`translate(0, ${legendAxisYOffset})`}
        ref={legendAxisGroupElementRef}
      />
      {planSpacesSorted.map((space) => {
        const isFocused = focusedSpaceId && focusedSpaceId === space.SPACE_ID;

        const selectionExists =
          spaceSelectionState.status === "selection-only" ||
          spaceSelectionState.status === "selection-and-peek";

        const spaceData = planDataAggregated[space.SPACE_ID];
        const metricValue = spaceData
          ? colorBy === "density"
            ? spaceData.avgDensityWhenUsed
            : spaceData.timeUsedPercent
          : null;

        const focusExists = spaceSelectionState.status !== "no-selection";

        const isSelected =
          selectionExists &&
          space.SPACE_ID === spaceSelectionState.selectedSpaceId;

        const isInvalid = typeof metricValue !== "number";

        const color = isInvalid
          ? "transparent"
          : colorBy === "density"
          ? densityColorScale(metricValue)
          : timeUsedColorScale(metricValue);

        return isInvalid ? null : (
          <circle
            key={space.SPACE_ID}
            cx={legendXScale(metricValue)}
            cy={26}
            r={isFocused ? 8 : 5}
            fillOpacity={isFocused || isSelected ? 1 : focusExists ? 0.8 : 0.8}
            fill={color}
            onClick={(evt) => {
              evt.stopPropagation();
              onClickArea(space.SPACE_ID);
            }}
            onMouseEnter={() => onMouseEnterArea(space.SPACE_ID)}
            onMouseLeave={() => {
              onMouseLeaveArea(space.SPACE_ID);
            }}
          />
        );
      })}
    </svg>
  );
};
