import { Box, LinearProgress, Paper, Typography } from "@mui/material";
import { Wrapper } from "lib/types";

export const Loader: Wrapper = ({ children }) => {
  return (
    <Box
      flex={1}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        height: "100%",
      }}
    >
      <Paper sx={{ width: 400 }} elevation={2}>
        <Typography sx={{ p: 1 }} variant={"caption"}>
          {children}
        </Typography>
        <LinearProgress />
      </Paper>
    </Box>
  );
};
