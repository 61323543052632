import { useContext, useEffect } from "react";

import { PlansContext, PlanSelectionContext } from "lib/contexts";
import { usePlanSelection } from "app/state/plan-selection";
import { Wrapper } from "lib/types";
import { useAppDispatch } from "app/hooks/use-app-dispatch";

export const PlanSelectionLoader: Wrapper = ({ children }) => {
  const { plans } = useContext(PlansContext);
  const { state, dispatch } = usePlanSelection(plans);

  const appDispatch = useAppDispatch();

  // TODO: this should be part of a pipeline
  useEffect(() => {
    appDispatch({ type: "spaceFunction.reset" });
  }, [state.selectedPlanId, appDispatch]);

  return (
    <PlanSelectionContext.Provider value={[state, dispatch]}>
      {children}
    </PlanSelectionContext.Provider>
  );
};
