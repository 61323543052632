import { AsyncDuckDB, AsyncDuckDBConnection } from "@duckdb/duckdb-wasm";
import * as arrow from "apache-arrow";

import {
  Plan,
  PlanImagesTableRow,
  PlansTableRow,
  SpacesTableRow,
} from "lib/types";
import { createFeatureCollection } from "./feature-collection";

export async function getMetaDataForPlan(db: AsyncDuckDB, planId: string) {
  const conn = await db.connect();

  const result = await conn.query<PlansTableRow & PlanImagesTableRow>(`
    SELECT p.PLAN_ID, * 
    FROM plans p
    INNER JOIN plan_images i
    ON p.PLAN_ID = i.PLAN_ID
    WHERE p.PLAN_ID = '${planId}'
  `);

  await conn.close();

  return result.get(0)!.toJSON();
}

export async function getAreaFeaturesForPlan(
  db: AsyncDuckDB,
  planId: string,
  planMeta: Plan["meta"]
) {
  const conn = await db.connect();

  const areasQuery = await conn.query<
    Pick<
      SpacesTableRow,
      | "SPACE_ID"
      | "SPACE_NAME"
      | "AREA_NAME"
      | "AREA_SQFT"
      | "AREA_SHAPE_POLYGON"
    >
  >(`
    SELECT
      SPACE_ID,
      SPACE_NAME,
      AREA_NAME,
      AREA_SQFT,
      AREA_SHAPE_POLYGON
    FROM spaces
    WHERE PLAN_ID = '${planId}'
    AND AREA_SHAPE = 'polygon'
  `);

  await conn.close();

  // areasQuery.get(0)?.AREA_SHAPE_POLYGON?.toJSON;
  const areas = areasQuery.toArray().map((a) => {
    return {
      area_name: a.AREA_NAME,
      space_name: a.SPACE_NAME,
      space_id: a.SPACE_ID,
      area_sqft: a.AREA_SQFT,
      polygon: a.AREA_SHAPE_POLYGON.toJSON(),
    };
  });
  return createFeatureCollection(areas, planMeta);
}

export async function getPlanSpaces(db: AsyncDuckDB, planId: string) {
  const conn = await db.connect();
  const result = await conn.query<{
    SPACE_ID: arrow.Utf8;
    AREA_SQFT: arrow.Float32;
    CAPACITY: arrow.Uint32;
    FUNCTION: arrow.Utf8;
    AREA_SHAPE: SpacesTableRow["AREA_SHAPE"];
    AREA_SHAPE_CIRCLE: SpacesTableRow["AREA_SHAPE_CIRCLE"];
    AREA_SHAPE_POLYGON: SpacesTableRow["AREA_SHAPE_POLYGON"];
    IMAGE_PIXELS_PER_METER: arrow.Float32;
  }>(`
    SELECT
      s.SPACE_ID,
      s.AREA_SQFT,
      s.CAPACITY,
      s.FUNCTION,
      AREA_SHAPE,
      AREA_SHAPE_CIRCLE,
      AREA_SHAPE_POLYGON,
      IMAGE_PIXELS_PER_METER,

    FROM spaces s
    INNER JOIN plan_images p
    ON p.PLAN_ID = s.PLAN_ID
    WHERE p.PLAN_ID = '${planId}'
  `);
  await conn.close();

  return result;
}

export async function getSpaceFunctions(db: AsyncDuckDB) {
  const conn = await db.connect();
  const result = await conn.query<{
    FUNCTION: arrow.Utf8;
    COUNT: arrow.Uint32;
  }>(`
    SELECT
      FUNCTION,
      COUNT(*) AS COUNT, 
    FROM spaces
    GROUP BY FUNCTION
  `);
  await conn.close();
  return result;
}

export async function getSpaceFunctionsForPlan(
  conn: AsyncDuckDBConnection,
  selectedPlanId: string
) {
  return await conn.query<{
    FUNCTION: arrow.Utf8;
    OCCURRENCES: arrow.Uint32;
  }>(`
    SELECT
      FUNCTION,
      CAST(COUNT(*) AS INTEGER) AS OCCURRENCES
    FROM spaces
    WHERE PLAN_ID = '${selectedPlanId}'
    AND FUNCTION IS NOT NULL
    GROUP BY FUNCTION
    ORDER BY OCCURRENCES DESC
  `);
}
