import {
  parse,
  parseISO,
  Interval,
  eachDayOfInterval,
  format,
  formatISO,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
} from "date-fns";

import { Reducer } from "@densityco/lib-state-management";
import { exhaustiveCheck } from "app/state";
import { CalendarAction, GlobalAction } from "./actions";

export const DAY_NAMES = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const;

export type DayName = typeof DAY_NAMES[number];

export type DateString = string;
export type MonthId = `${number}-${number}`; // eg. 2022-09
export type WeekId = `${number},${number}`; // eg. 2022,43

export type DateInterval = {
  start: DateString;
  end: DateString;
};

export function extractMonthId(date: Date): MonthId {
  return format(date, "yyyy-MM") as MonthId;
}

export function parseMonthId(monthId: MonthId) {
  return parse(monthId, "yyyy-MM", new Date());
}

export function extractWeekId(date: Date): WeekId {
  return format(date, "YYYY,ww", {
    useAdditionalWeekYearTokens: true,
  }) as WeekId;
}

export function parseWeekId(weekId: WeekId) {
  return parse(weekId, "YYYY,ww", new Date(), {
    useAdditionalWeekYearTokens: true,
  });
}

export function extractDateString(date: Date): DateString {
  return formatISO(date, { representation: "date" });
}

export function extractDayName(date: Date): DayName {
  return format(date, "EEEE") as DayName;
}

export function realizeDate(dateString: DateString): Date {
  return parseISO(dateString);
}

export function getDateIntervalFromTimeframe(timeframe: CalendarTimeframe) {
  if (timeframe.type === "month") {
    const date = parseMonthId(timeframe.monthId);
    const start = extractDateString(startOfMonth(date));
    const end = extractDateString(endOfMonth(date));
    return { start, end };
  } else if (timeframe.type === "week") {
    const date = parseWeekId(timeframe.weekId);
    const start = extractDateString(startOfWeek(date));
    const end = extractDateString(endOfWeek(date));
    return { start, end };
  } else {
    // FIXME: this is dumb
    return {
      start: "2022-10-01",
      end: "2023-01-31",
    };
  }
}

export function eachUniqueWeekOfInterval(interval: Interval) {
  // return eachDayOfInterval(interval).filter((date) => isSunday(date));
  const uniqueWeeks = [];
  const weeksAccountedFor = new Set<WeekId>();
  for (const date of eachDayOfInterval(interval)) {
    const weekId = extractWeekId(date);
    if (weeksAccountedFor.has(weekId)) continue;
    uniqueWeeks.push({
      weekId,
      date,
    });
    weeksAccountedFor.add(weekId);
  }
  return uniqueWeeks;
}

export type CalendarTimeframe =
  | { type: "all" }
  | {
      type: "month";
      monthId: MonthId;
    }
  | {
      type: "week";
      weekId: WeekId;
    };

export type CalendarState = {
  readonly timeframe: CalendarTimeframe;
  readonly disabledDayNames: Set<DayName>;
  readonly disabledDates: Set<DateString>;
};

export const initialState: CalendarState = {
  disabledDayNames: new Set<DayName>(["Saturday", "Sunday"]),
  disabledDates: new Set(),
  timeframe: { type: "month", monthId: "2022-12" },
};

function toggleItemInSet<T>(prevSet: Set<T>, item: T) {
  const nextSet = new Set(prevSet);
  if (nextSet.has(item)) {
    nextSet.delete(item);
  } else {
    nextSet.add(item);
  }
  return nextSet;
}

export const reducer: Reducer<CalendarState, GlobalAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "calendar.initialize": {
      const { validStartDate, validEndDate } = action;
      return {
        ...initialState,
        validStartDate,
        validEndDate,
      };
    }
    case "calendar.timeframe.selected": {
      const { timeframe } = action;
      return {
        ...state,
        timeframe,
      };
    }
    case "calendar.timeframe.selectedAll": {
      return {
        ...state,
        timeframe: { type: "all" },
      };
    }
    case "calendar.timeframe.selectedMonth": {
      const { monthId } = action;
      return {
        ...state,
        timeframe: { type: "month", monthId },
      };
    }
    case "calendar.timeframe.selectedWeek": {
      const { weekId } = action;
      return {
        ...state,
        timeframe: { type: "week", weekId },
      };
    }
    case "calendar.disabledDates.toggle": {
      const nextDisabledDates = toggleItemInSet(
        state.disabledDates,
        action.date
      );
      return {
        ...state,
        disabledDates: nextDisabledDates,
      };
    }
    case "calendar.disabledDayNames.toggle": {
      const nextDisabledDayNames = toggleItemInSet(
        state.disabledDayNames,
        action.dayName
      );
      return {
        ...state,
        disabledDayNames: nextDisabledDayNames,
      };
    }
    default: {
      exhaustiveCheck<CalendarAction>(action);
      return state;
    }
  }
};
