import { Feature, Polygon } from "geojson";
import { AreaFeature, AreaFeatureCollection, Plan } from "lib/types";

export function createCircleFeature(
  cx: number,
  cy: number,
  radius: number,
  scale: number
): Feature<Polygon> {
  const vertices = [];
  for (let angleDeg = 0; angleDeg <= 360; angleDeg += 15) {
    const angle = angleDeg * (Math.PI / 180);
    const x = cx + radius * Math.sin(angle);
    const y = cy + radius * Math.cos(angle);
    vertices.push([Math.round(x * scale), Math.round(y * scale)]);
  }
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [vertices],
    },
    properties: {},
  };
}

export function createPolygonFeature(
  polygon: Array<{ x: number; y: number }>,
  scale: number
): Feature<Polygon> {
  return {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [
        polygon
          .concat([polygon[0]])
          .map((v) => [Math.round(v.x * scale), Math.round(v.y * scale)]),
      ],
    },
    properties: {},
  };
}

export function createFeatureCollection(
  areas: Array<{
    space_id: string;
    area_sqft: number;
    area_name: string;
    space_name: string;
    polygon: Array<{ x: number; y: number }>;
  }>,
  planMeta: Plan["meta"]
): AreaFeatureCollection {
  const scale = planMeta.IMAGE_PIXELS_PER_METER;
  const features = areas.map<AreaFeature>((area) => {
    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          area.polygon
            .concat([area.polygon[0]])
            .map((v) => [Math.round(v.x * scale), Math.round(v.y * scale)]),
        ],
      },
      properties: {
        // id: area.id,
        // name: area.name,
        space_id: area.space_id,
        area_sqft: area.area_sqft,
        area_name: area.area_name,
        space_name: area.space_name,

        // area_sqft:
        //   Math.abs(
        //     d3.polygonArea(
        //       area.polygon_verticies.map((v) => [
        //         v.x_from_origin_meters,
        //         v.y_from_origin_meters,
        //       ])
        //     )
        //   ) * 10.764,
      },
    };
  });

  return {
    type: "FeatureCollection",
    features,
  };
}
