import { useContext, useState, useEffect } from "react";
import {
  DatabaseContext,
  PlanSelectionContext,
  PlanContextType,
  PlanContext,
} from "lib/contexts";
import { getMetaDataForPlan, getAreaFeaturesForPlan } from "lib/query";
import { Resolvable } from "lib/resolvable";
import { Wrapper, Plan } from "lib/types";
import { ErrorState } from "components/error-state";
import { Loader } from "components/loader";
import { DATASET_PATH } from "lib/dataset";
import { getStoredObject, s3 } from "lib/storage";

export const PlanLoader: Wrapper = ({ children }) => {
  const db = useContext(DatabaseContext);
  const [planSelectionState] = useContext(PlanSelectionContext);
  const { selectedPlanId } = planSelectionState;
  const [state, setState] = useState<Resolvable<PlanContextType>>({
    status: Resolvable.Status.NONE,
  });

  useEffect(() => {
    let imageUrl: string | null = null;
    setState(Resolvable.updateRunning());
    (async () => {
      const meta = await getMetaDataForPlan(db, selectedPlanId);
      if (!meta) {
        return setState(
          Resolvable.failWith(`Could not find meta for Plan ${selectedPlanId}`)
        );
      }

      const areaFeatureCollection = await getAreaFeaturesForPlan(
        db,
        selectedPlanId,
        meta
      );

      const key = `${DATASET_PATH}/plans_by_id/${meta.PLAN_ID}/${meta.IMAGE_NAME}`;
      const imageBytes = await getStoredObject(key);

      // For now only supporting .png and .jpg
      const mimeType = meta.IMAGE_NAME.endsWith(".png")
        ? "image/png"
        : "image/jpeg";
      const blob = new Blob([imageBytes], {
        type: mimeType,
      });
      imageUrl = URL.createObjectURL(blob);

      const plan: Plan = { meta, imageUrl, areaFeatureCollection };

      setState(Resolvable.completeWith({ plan }));
    })();

    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    };
  }, [db, selectedPlanId]);

  switch (state.status) {
    case Resolvable.Status.NONE:
    case Resolvable.Status.RUNNING: {
      return <Loader>Loading plan...</Loader>;
    }
    case Resolvable.Status.FAILED: {
      return <ErrorState>Error loading plan.</ErrorState>;
    }
    case Resolvable.Status.COMPLETED: {
      return (
        <PlanContext.Provider value={state.value}>
          {children}
        </PlanContext.Provider>
      );
    }
  }
};
