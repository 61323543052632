import * as AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_ACCESS_KEY,
});
export const s3 = new AWS.S3({
  region: "us-east-1",
});

// async function storeAsset(key: string, etag: string, data: Uint8Array) {
//   const blob = new Blob([data]);
//   return db.put({
//     _id: key,
//     _rev: etag,
//     _attachments: {
//       [key]: {
//         content_type: "application/x-parquet",
//         data: blob,
//       },
//     },
//   });
// }

// async function getAsset(key: string) {
//   return new Promise<Asset>((resolve, reject) => {
//     db.get(key, { attachments: true, binary: true })
//       .then(
//         async (doc) => {
//           const attachment = doc._attachments ? doc._attachments[key] : null;
//           if (attachment && "data" in attachment) {
//             const buffer = await (attachment.data as Blob).arrayBuffer();
//             const data = new Uint8Array(buffer);
//             resolve({
//               etag: doc._rev,
//               data,
//             });
//           }
//         },
//         (error) => reject(error)
//       )
//       .catch((error) => reject(error));
//   });
// }

export async function getStoredObject(key: string) {
  return new Promise<Uint8Array>(async (resolve, reject) => {
    s3.getObject(
      {
        Bucket: "roi-prototype-data",
        Key: key,
        // IfNoneMatch: existingAsset?.etag || undefined,
      },
      async (err, data) => {
        if (err) {
          reject(err);
        } else {
          if (data.Body) {
            if (data.ETag) {
              // await storeAsset(key, data.ETag, data.Body as Uint8Array);
            }
            resolve(data.Body as Uint8Array);
          } else {
            reject();
          }
        }
      }
    );
  });
}
