import * as aq from "arquero";
import ColumnTable, { Params } from "arquero/dist/types/table/column-table";
import { Struct } from "arquero/dist/types/table/transformable";

import {
  CalendarState,
  DateString,
  DAY_NAMES,
  getDateIntervalFromTimeframe,
} from "../app/state/calendar";
import { SpaceFunction } from "../app/state/space-function";
import { VisualizationScope } from "./types";

export type OccupancyDataRow = {
  SPACE_ID: string;
  PLAN_ID: string;
  ORGANIZATION_NAME: string;
  BUILDING_NAME: string;
  FLOOR_NAME: string;
  SPACE_NAME: string;
  AREA_SQFT: number;
  CAPACITY: number;
  FUNCTION: string;
  LABELS: Array<{ id: string; name: string }>;
  COST_PER_SQFT: number;
  LOCAL_DATE: DateString;
  TIME_USED_PERCENT: number;
  AVG_OCCUPANCY_WHEN_USED: number;
  AVG_TEAM_DENSITY_WHEN_USED: number;
};

export function filterTableByPlan(table: ColumnTable, planId: string) {
  return table
    .params({
      planId,
    })
    .filter((d: Struct, $: Params) => {
      return d.PLAN_ID === $.planId;
    })
    .reify();
}

export function filterTableBySpaceSelection(
  table: ColumnTable,
  selectedSpaceFunctions: Set<SpaceFunction>
) {
  if (selectedSpaceFunctions.size === 0) return table;
  return table
    .params({
      selectedSpaceFunctions,
    })
    .filter((d: Struct, $: Params) => {
      return aq.op.has($.selectedSpaceFunctions, d.FUNCTION);
    })
    .reify();
}

export function filterTableToExcludeCapacityOfOne(table: ColumnTable) {
  return table.filter((d: Struct) => d.CAPACITY !== 1);
}

export function filterTableByCalendarSelection(
  table: ColumnTable,
  calendarState: CalendarState
) {
  const { timeframe, disabledDayNames, disabledDates } = calendarState;

  const interval = getDateIntervalFromTimeframe(timeframe);

  const result = table
    .params({
      DAY_NAMES,
      disabledDayNames,
      disabledDates,
      intervalStart: interval.start,
      intervalEnd: interval.end,
    })
    .derive({
      LOCAL_DAY_NAME: (d: Struct, $: Params) =>
        $.DAY_NAMES[aq.op.utcdayofweek(aq.op.parse_date(d.LOCAL_DATE))],
    })
    .filter((d: Struct, $: Params) => {
      return (
        !aq.op.has($.disabledDayNames, d.LOCAL_DAY_NAME) &&
        !aq.op.has($.disabledDates, d.LOCAL_DATE) &&
        d.LOCAL_DATE >= $.intervalStart &&
        d.LOCAL_DATE <= $.intervalEnd
      );
    })
    .reify();

  return result;
}
