import { useContext, useEffect } from "react";
import * as aq from "arquero";

import { DatabaseContext, PortfolioSpacesTableContext } from "lib/contexts";
import { useResolvable, Resolvable } from "lib/resolvable";
import { Wrapper } from "lib/types";
import { ErrorState } from "components/error-state";
import { Loader } from "components/loader";
import { Resolve } from "components/resolve";

export const SpacesLoader: Wrapper<{
  organizationName: string;
}> = ({ organizationName, children }) => {
  const db = useContext(DatabaseContext);
  const [state, setState] = useResolvable<aq.internal.ColumnTable>();

  useEffect(() => {
    setState(Resolvable.updateRunning());
    (async () => {
      setState(Resolvable.updateRunning());
      const conn = await db.connect();

      const result = await conn.query(`
          SELECT
            p.ORGANIZATION_NAME,
            p.BUILDING_NAME,
            p.FLOOR_NAME,
            p.PLAN_ID,
            s.SPACE_ID,
            s.SPACE_NAME,
            s.AREA_SQFT,
            s.FUNCTION,
            s.CAPACITY,
            s.LABELS,
            p.COST_PER_SQFT,
          FROM spaces s
          INNER JOIN plans p
          ON p.PLAN_ID = s.PLAN_ID
          WHERE p.ORGANIZATION_NAME = '${organizationName}'
      `);

      await conn.close();
      setState(Resolvable.completeWith(aq.fromArrow(result)));
    })();
  }, [db, setState, organizationName]);

  return (
    <Resolve
      resolvable={state}
      onRunning={() => <Loader>Loading occupancy data...</Loader>}
      onFailed={(error) => {
        console.error(error);
        return <ErrorState>Error loading occupancy data.</ErrorState>;
      }}
      onCompleted={(table) => {
        return (
          <PortfolioSpacesTableContext.Provider value={{ table }}>
            {children}
          </PortfolioSpacesTableContext.Provider>
        );
      }}
    />
  );
};
