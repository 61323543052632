import { Reducer } from "@densityco/lib-state-management";
import { GlobalAction } from "./actions";

import * as Calendar from "./calendar";
import * as SpaceFunction from "./space-function";

export const reducer = combineReducers({
  calendar: Calendar.reducer,
  spaceFunction: SpaceFunction.reducer,
});

export type GlobalState = ReducerStateType<typeof reducer>;
export const initialState: GlobalState = {
  calendar: Calendar.initialState,
  spaceFunction: SpaceFunction.initialState,
};

// export const [globalActionStream, globalDispatch] =
//   createActionStream<GlobalAction>();
// export const store = createStore(reducer, initialState, globalActionStream);

type ReducerMapping<S, A> = {
  [K in keyof S]: Reducer<S[K], A>;
};

type ReducerStateType<R> = R extends Reducer<infer S, any> ? S : never;

export function combineReducers<S>(
  reducers: ReducerMapping<S, GlobalAction>
): Reducer<S, GlobalAction> {
  var reducerKeys = Object.keys(reducers) as Array<keyof typeof reducers>;
  return function combination(state: S, action: GlobalAction) {
    var hasChanged = false;
    var nextState: any = {};
    for (let i = 0; i < reducerKeys.length; i++) {
      let key = reducerKeys[i];
      nextState[key] = reducers[key](state[key], action);
      hasChanged = hasChanged || nextState[key] !== state[key];
    }
    return hasChanged ? nextState : state;
  };
}

export function exhaustiveCheck<T extends GlobalAction>(
  action: Exclude<GlobalAction, T>
) {}
