import React from "react";
import { Resolvable } from "lib/resolvable";

type ResolveProps<T, E = unknown> = {
  resolvable: Resolvable<T, E>;
  onRunning: () => React.ReactNode;
  onFailed: (error: E) => React.ReactNode;
  onCompleted: (value: T) => React.ReactNode;
};

export function Resolve<ValueType, ErrorType = unknown>(
  props: ResolveProps<ValueType, ErrorType>
) {
  switch (props.resolvable.status) {
    case Resolvable.Status.NONE:
      return null;
    case Resolvable.Status.RUNNING: {
      const children = props.onRunning();
      return <React.Fragment>{children}</React.Fragment>;
    }
    case Resolvable.Status.FAILED: {
      const children = props.onFailed(props.resolvable.error);
      return <React.Fragment>{children}</React.Fragment>;
    }
    case Resolvable.Status.COMPLETED:
      const children = props.onCompleted(props.resolvable.value);
      return <React.Fragment>{children}</React.Fragment>;
  }
}
