import { useContext, useState, useEffect } from "react";
import * as d3 from "d3";

import {
  UserContext,
  DatabaseContext,
  PlansContextType,
  PlansContext,
} from "lib/contexts";
import { PlansListItem } from "app/state/plan-selection";
import { Resolvable } from "lib/resolvable";
import { Wrapper } from "lib/types";
import { ErrorState } from "components/error-state";
import { Loader } from "components/loader";

export const PlansLoader: Wrapper = (props) => {
  const [user] = useContext(UserContext);
  const db = useContext(DatabaseContext);
  const [state, setState] = useState<Resolvable<PlansContextType>>({
    status: Resolvable.Status.NONE,
  });

  useEffect(() => {
    setState(Resolvable.updateRunning());
    (async () => {
      let plans = [];

      const conn = await db.connect();

      const result = await conn.query(`
          SELECT
            PLAN_ID,
            ORGANIZATION_NAME,
            BUILDING_NAME,
            FLOOR_NAME,
            COST_PER_SQFT
          FROM plans
        `);

      plans = result.toArray().map<PlansListItem>((p) => {
        return {
          ORGANIZATION_NAME: p.ORGANIZATION_NAME,
          BUILDING_NAME: p.BUILDING_NAME,
          COST_PER_SQFT: p.COST_PER_SQFT,
          FLOOR_NAME: p.FLOOR_NAME,
          PLAN_ID: p.PLAN_ID,
        };
      });
      await conn.close();

      const allOrgNames = Array.from(
        new Set(plans.map((p) => p.ORGANIZATION_NAME))
      ).sort((a, b) => {
        return d3.ascending(a.toLowerCase(), b.toLowerCase());
      });
      if (user.mode === "user") {
        plans = plans.filter(
          (p) => p.ORGANIZATION_NAME === user.organizationName
        );
      }

      setState(Resolvable.completeWith({ plans, allOrgNames }));
    })();
  }, [db, user]);

  switch (state.status) {
    case Resolvable.Status.NONE:
    case Resolvable.Status.RUNNING: {
      return <Loader>Loading plans...</Loader>;
    }
    case Resolvable.Status.FAILED: {
      return <ErrorState>Error loading plans.</ErrorState>;
    }
    case Resolvable.Status.COMPLETED: {
      return (
        <PlansContext.Provider value={state.value}>
          {props.children}
        </PlansContext.Provider>
      );
    }
  }
};
