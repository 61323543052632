import * as d3 from "d3";
import { saveAs } from "file-saver";

import { PlanSelectionContext, PortfolioUsageTableContext } from "lib/contexts";
import {
  filterTableByCalendarSelection,
  filterTableByPlan,
  filterTableBySpaceSelection,
  filterTableToExcludeCapacityOfOne,
} from "lib/occupancy";
// import { SpaceSelectionContext } from "lib/space-selection";
import { useCallback, useContext, useMemo } from "react";
import { useAppStore } from "./use-app-store";

export function useCSVDownload() {
  const [{ selectedPlanId, colorBy }] = useContext(PlanSelectionContext);
  // const [spaceSelectionState] = useContext(SpaceSelectionContext);
  const { table: portfolioTable } = useContext(PortfolioUsageTableContext);

  const { calendar: calendarState, spaceFunction: spaceFunctionState } =
    useAppStore();

  const table = useMemo(() => {
    let table = filterTableByCalendarSelection(
      filterTableBySpaceSelection(
        filterTableByPlan(portfolioTable, selectedPlanId),
        spaceFunctionState.selected
      ),
      calendarState
    );
    if (colorBy === "density") {
      table = filterTableToExcludeCapacityOfOne(table);
    }
    return table;
  }, [
    portfolioTable,
    selectedPlanId,
    spaceFunctionState.selected,
    calendarState,
    colorBy,
  ]);

  const csvText = useMemo(() => {
    return d3.csvFormat(table.orderby("SPACE_ID", "LOCAL_DATE").objects(), [
      "ORGANIZATION_NAME",
      "PLAN_ID",
      "SPACE_ID",
      "BUILDING_NAME",
      "FLOOR_NAME",
      "SPACE_NAME",
      "AREA_SQFT",
      "CAPACITY",
      "FUNCTION",
      "COST_PER_SQFT",
      "LOCAL_DATE",
      "TIME_USED_PERCENT",
      "AVG_OCCUPANCY_WHEN_USED",
      "AVG_TEAM_DENSITY_WHEN_USED",
    ]);
  }, [table]);

  const onDownload = useCallback(
    (filenamePrefix: string) => {
      const blob = new Blob([csvText], { type: "text/csv;charset=utf-8" });
      saveAs(blob, `${filenamePrefix}.csv`);
    },
    [csvText]
  );

  return onDownload;
}
