import React, { useContext, useMemo } from "react";
import { Box, Divider, Typography } from "@mui/material";
import * as dust from "@density/dust/dist/tokens/dust.tokens";
import * as aq from "arquero";
import { Params, Struct } from "arquero/dist/types/table/transformable";

import { useAppDispatch } from "app/hooks/use-app-dispatch";
import { useAppStore } from "app/hooks/use-app-store";
import { isSpaceFunctionSelected } from "app/state/space-function";
import {
  getFocusedSpaceId,
  SpaceSelectionContext,
} from "app/state/space-selection";
import { FloorDetail } from "components/floor-detail";
import { FocusedSpaceDetail } from "components/space-detail";
import { SpaceFunctionChip } from "components/space-function-chip";
import {
  PlanSelectionContext,
  PortfolioSpacesTableContext,
  PortfolioUsageTableContext,
} from "lib/contexts";
import { filterTableByCalendarSelection } from "lib/occupancy";
import { SpaceMeta, SpaceUsageData } from "lib/types";

export const SpacePanel: React.FunctionComponent = () => {
  const [{ selectedPlanId }] = useContext(PlanSelectionContext);
  const [spaceSelectionState] = useContext(SpaceSelectionContext);
  const focusedSpaceId = getFocusedSpaceId(spaceSelectionState);

  const { table: portfolioUsageTable } = useContext(PortfolioUsageTableContext);
  const { table: portfolioSpacesTable } = useContext(
    PortfolioSpacesTableContext
  );

  const { calendar: calendarState, spaceFunction: spaceFunctionState } =
    useAppStore();
  const dispatch = useAppDispatch();

  const filteredPortfolioTable = filterTableByCalendarSelection(
    portfolioUsageTable,
    calendarState
  );

  const planTable = useMemo(() => {
    return filteredPortfolioTable
      .params({ planId: selectedPlanId })
      .filter((d: Struct, $: Params) => d.PLAN_ID === $.planId);
  }, [filteredPortfolioTable, selectedPlanId]);

  const spaceData: (SpaceMeta & SpaceUsageData) | null = useMemo(() => {
    if (focusedSpaceId === null) return null;
    const result = planTable
      .params({ spaceId: focusedSpaceId })
      .filter((d: Struct, $: Params) => d.SPACE_ID === $.spaceId)
      .rollup({
        spaceId: (d: Struct) => aq.op.any(d.SPACE_ID),
        // name: (d: Struct) => aq.op.any(d.SPACE_NAME),
        // capacity: (d: Struct) => aq.op.any(d.CAPACITY),
        // spaceFunction: (d: Struct) => aq.op.any(d.FUNCTION),
        // areaSqft: (d: Struct) => aq.op.any(d.AREA_SQFT),
        // costPerSqft: (d: Struct) => aq.op.any(d.COST_PER_SQFT),
        // labels: (d: Struct) => aq.op.any(d.LABELS),
        avgOccupancyWhenUsed: (d: Struct) =>
          aq.op.mean(d.AVG_OCCUPANCY_WHEN_USED),
        avgDensityWhenUsed: (d: Struct) =>
          aq.op.mean(d.AVG_TEAM_DENSITY_WHEN_USED),
        timeUsedPercent: (d: Struct) => aq.op.mean(d.TIME_USED_PERCENT),
      });
    return result.objects()[0];
  }, [planTable, focusedSpaceId]);

  const spaceMeta: SpaceMeta | null = useMemo(() => {
    if (focusedSpaceId === null) return null;
    const results = portfolioSpacesTable
      .params({ focusedSpaceId })
      .filter((d: Struct, $: Params) => d.SPACE_ID === $.focusedSpaceId)
      .objects();

    if (!results.length) return null;
    const {
      SPACE_NAME: name,
      CAPACITY: capacity,
      FUNCTION: spaceFunction,
      AREA_SQFT: areaSqft,
      COST_PER_SQFT: costPerSqft,
      LABELS: labels,
    } = results[0];
    return {
      name,
      capacity,
      spaceFunction,
      areaSqft,
      costPerSqft,
      labels,
    };
  }, [portfolioSpacesTable, focusedSpaceId]);

  const spaceUsageData: SpaceUsageData | null = useMemo(() => {
    if (spaceData === null) return null;
    const { avgOccupancyWhenUsed, avgDensityWhenUsed, timeUsedPercent } =
      spaceData;
    return { avgOccupancyWhenUsed, avgDensityWhenUsed, timeUsedPercent };
  }, [spaceData]);

  const allSpaceFunctionsInOrg = useMemo(() => {
    return portfolioUsageTable
      .groupby("FUNCTION")
      .rollup({ count: (d: Struct) => aq.op.distinct(d.SPACE_ID) });
  }, [portfolioUsageTable]);
  const spaceFunctionsOnCurrentPlan = useMemo(() => {
    return planTable
      .groupby("FUNCTION")
      .rollup({ count: (d: Struct) => aq.op.distinct(d.SPACE_ID) });
  }, [planTable]);

  type Row = { FUNCTION: string; count: number };
  const lookup: { [key: string]: number } = spaceFunctionsOnCurrentPlan
    .objects()
    .reduce((lookup: { [key: string]: number }, row: Row) => {
      if (typeof row.FUNCTION !== "string") return lookup;
      lookup[row.FUNCTION] = row.count;
      return lookup;
    }, {} as { [key: string]: number });

  return (
    // <Box display="aflex" flexDirection="row">
    <Box
      sx={{ width: "240px", borderLeft: `1px solid ${dust.Gray100}` }}
      display="flex"
      flexGrow={0}
      flexShrink={0}
      flexWrap="nowrap"
      position="relative"
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflow={"auto"}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box flex="1" sx={{ overflowY: "auto" }}>
            {focusedSpaceId ? (
              <FocusedSpaceDetail
                focusedSpaceId={focusedSpaceId}
                spaceMeta={spaceMeta ? spaceMeta : undefined}
                spaceUsageData={spaceUsageData ? spaceUsageData : undefined}
              />
            ) : (
              <FloorDetail />
            )}
          </Box>
          <Divider />
          <Box flex={0}>
            <Typography fontSize={12} sx={{ px: 1, color: dust.Gray300 }}>
              All Space Types (portfolio)
            </Typography>
            {(
              allSpaceFunctionsInOrg.objects() as Array<{
                FUNCTION: string;
                count: number;
              }>
            ).map((f) => {
              const count = lookup[f.FUNCTION];
              const isOnFloorplan = count !== undefined;

              const isSelected = isSpaceFunctionSelected(
                spaceFunctionState,
                f.FUNCTION
              );

              return f.FUNCTION === null ? null : (
                <SpaceFunctionChip
                  key={f.FUNCTION}
                  name={f.FUNCTION}
                  count={isOnFloorplan ? count : 0}
                  isDisabled={!isOnFloorplan || count === 0}
                  isSelected={isSelected}
                  color={isSelected ? dust.Blue400 : undefined}
                  onClick={() => {
                    dispatch({
                      type: "spaceFunction.toggleItem",
                      item: f.FUNCTION,
                    });
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
    // </Box>
  );
};
