import { useContext, useMemo } from "react";
import * as d3 from "d3";
import * as dust from "@density/dust/dist/tokens/dust.tokens";

import { ColorScaleContext, PlanContextualDataContext } from "lib/contexts";
import { Wrapper } from "lib/types";

export const ColorScaleProvider: Wrapper = ({ children }) => {
  const contextualData = useContext(PlanContextualDataContext);

  const colorScale = useMemo(() => {
    const { minDensity, maxDensity, medianDensity } = contextualData;

    const scaleDensity = d3
      .scaleLinear<string>()
      .domain([minDensity, medianDensity, maxDensity])
      .range([dust.Green500, dust.Yellow300, dust.Red500])
      .clamp(true);

    const scaleTimeUsed = d3
      .scaleLinear<string>()
      .domain([1.0, 0.5, 0])
      .range([dust.Green500, dust.Yellow300, dust.Red500])
      .clamp(true);

    return { scaleDensity, scaleTimeUsed };
  }, [contextualData]);

  return (
    <ColorScaleContext.Provider value={colorScale}>
      {children}
    </ColorScaleContext.Provider>
  );
};
