import { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
} from "@mui/material";
import * as dust from "@density/dust/dist/tokens/dust.tokens";

import { PlanSelectionContext } from "lib/contexts";
import { ChosenMetric } from "lib/types";
import { Icons } from "@density/dust";
import React from "react";
import { useCSVDownload } from "app/hooks/use-csv-download";

export const MainToolbar: React.FunctionComponent = () => {
  const [state, dispatch] = useContext(PlanSelectionContext);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      sx={{ pl: 2, pt: 2 }}
    >
      <FormControl size="small" sx={{ m: 1, width: 184 }}>
        <InputLabel>Metric</InputLabel>
        <Select
          label={"Metric"}
          value={state.colorBy}
          onChange={(evt) => {
            dispatch({
              type: "select-color-by",
              value: evt.target.value as ChosenMetric,
            });
          }}
        >
          <MenuItem value={"density"}>Density</MenuItem>
          <MenuItem value={"time-used"}>Time Used</MenuItem>
        </Select>
        <FormHelperText>
          {state.colorBy === "density"
            ? "sqft per person, when used"
            : "% of working hours in use"}
        </FormHelperText>
      </FormControl>
      <Box flex={1}></Box>
      <Box>
        <SaveCSV />
      </Box>
    </Box>
  );
};

const CSVButton = styled(Button)({
  color: dust.Gray300,
  borderColor: dust.Gray100,
  "&:hover": {
    color: dust.Gray400,
    borderColor: dust.Gray200,
  },
  "&:active": {
    color: dust.Gray400,
    borderColor: dust.Gray400,
  },
});

const SaveCSV: React.FunctionComponent = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filenamePrefix, setFilenamePrefix] = useState("filename");
  const downloadCSV = useCSVDownload();

  return (
    <Box sx={{ px: 1 }}>
      <FormControl>
        <CSVButton
          variant="outlined"
          startIcon={<Icons.DownloadArrow size={16} />}
          onClick={(v) => setDialogOpen(true)}
        >
          CSV
        </CSVButton>
      </FormControl>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Export CSV File</DialogTitle>
        <DialogContent>
          <FormHelperText>Enter a filename to save</FormHelperText>
          <FormControl>
            <OutlinedInput
              id="outlined-adornment-weight"
              value={filenamePrefix}
              onChange={(evt) => setFilenamePrefix(evt.target.value)}
              endAdornment={
                <InputAdornment position="end">.csv</InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              downloadCSV(filenamePrefix);
              setDialogOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
