import { useContext, useEffect, useMemo, useRef, useState } from "react";
import * as arrow from "apache-arrow";
import * as d3 from "d3";
import * as colors from "@density/proto-ui-colors";

import { DatabaseContext } from "lib/contexts";
import { Resolvable } from "lib/resolvable";
import { Box, LinearProgress } from "@mui/material";
import { ErrorState } from "./error-state";
import useSize from "@react-hook/size";

type QueryCountFrequency = {
  OCCUPANCY: arrow.Uint32;
  OCCURRENCES: arrow.Uint32;
};

type QueryCountFrequencyByHour = {
  LOCAL_HOUR: arrow.Uint32;
  OCCUPANCY: arrow.Uint32;
  OCCURRENCES: arrow.Uint32;
};

type State = Resolvable<{
  countFrequency: arrow.Table<QueryCountFrequency>;
  // countFrequencyByHour: arrow.Table<QueryCountFrequencyByHour>;
}>;

export const CountFrequencyGraphic: React.FunctionComponent<{
  spaceId: string;
}> = ({ spaceId }) => {
  const db = useContext(DatabaseContext);

  const [state, setState] = useState<State>({ status: Resolvable.Status.NONE });

  useEffect(() => {
    setState(Resolvable.updateRunning());
    async function getData() {
      const conn = await db.connect();
      // const countFrequencyByHour = await conn.query(`

      //   SELECT
      //     LOCAL_HOUR,
      //     OCCUPANCY,
      //     CAST(SUM(OCCURRENCES) AS INTEGER) AS OCCURRENCES
      //   FROM count_frequency_by_space_by_day
      //   WHERE SPACE_ID = '${spaceId}'
      //   GROUP BY SPACE_ID, LOCAL_HOUR, OCCUPANCY
      //   ORDER BY SPACE_ID, LOCAL_HOUR, OCCUPANCY

      // `);
      // const countFrequencyByHour = await conn.query<QueryCountFrequencyByHour>(`
      //   SELECT LOCAL_HOUR, OCCUPANCY, OCCURRENCES
      //   FROM occupancy_frequency
      //   WHERE SPACE_ID = '${spaceId}'
      // `);

      const countFrequency = await conn.query<QueryCountFrequency>(`
        SELECT
          OCCUPANCY,
          CAST(SUM(OCCURRENCES) AS INTEGER) AS OCCURRENCES
        FROM count_frequency_by_space_by_day
        WHERE SPACE_ID = '${spaceId}'
        GROUP BY OCCUPANCY
        ORDER BY OCCUPANCY
      `);
      setState(Resolvable.completeWith({ countFrequency }));
    }
    getData();
  }, [db, spaceId]);

  switch (state.status) {
    case Resolvable.Status.NONE:
    case Resolvable.Status.RUNNING: {
      return <LinearProgress />;
    }
    case Resolvable.Status.FAILED: {
      console.error(state.error);
      return (
        <ErrorState>Error generating data for frequency graphic.</ErrorState>
      );
    }
    case Resolvable.Status.COMPLETED: {
      const { countFrequency } = state.value;
      return (
        <Graphic
          countFrequency={countFrequency}
          // countFrequencyByHour={countFrequencyByHour}
        />
      );
    }
  }
};

const padding = {
  top: 8,
  right: 4,
  bottom: 16,
  left: 4,
};

const Graphic: React.FunctionComponent<{
  countFrequency: arrow.Table<QueryCountFrequency>;
  // countFrequencyByHour: arrow.Table<QueryCountFrequencyByHour>;
}> = ({ countFrequency }) => {
  const containerElementRef = useRef<HTMLElement>(null);
  const xAxisGroupElementRef = useRef<SVGGElement>(null);
  const frequencyRecords = useMemo(() => {
    return countFrequency.toArray().map((d) => ({
      occupancy: d.OCCUPANCY as number,
      occurrences: d.OCCURRENCES as number,
    }));
  }, [countFrequency]);

  const [width, height] = useSize(containerElementRef);

  const scaleX = useMemo(() => {
    const max = Math.max(5, d3.max(frequencyRecords, (d) => d.occupancy)!);

    return d3
      .scaleBand<number>()
      .domain(d3.range(max + 1))
      .range([padding.left, width - padding.right])
      .padding(0.15);
  }, [frequencyRecords, width]);

  const scaleY = useMemo(() => {
    const max = d3.max(frequencyRecords, (d) => d.occurrences)!;
    return d3
      .scaleLinear()
      .domain([0, max])
      .range([height - padding.bottom, padding.top]);
  }, [frequencyRecords, height]);

  // xAxis
  useEffect(() => {
    const group = xAxisGroupElementRef.current;
    if (!group) return;
    const values = scaleX.domain();
    const numValues = values.length;
    const axis = d3
      .axisBottom(scaleX)
      .tickSizeOuter(0)
      .tickValues(
        numValues < 15
          ? values
          : numValues < 70
          ? values.filter((d, i) => 0 === i % 5)
          : numValues < 150
          ? values.filter((d, i) => 0 === i % 10)
          : values.filter((d, i) => 0 === i % 20)
      );
    const selection = d3.select(group);
    selection.call(axis);
    selection.selectAll("line,path").attr("stroke", colors.Gray200);
    selection
      .selectAll("text")
      .attr("font-family", "Aeonik")
      .attr("fill", colors.Gray700);
  }, [scaleX]);

  return (
    <Box width={"100%"} height={64} ref={containerElementRef}>
      <svg {...{ width, height }}>
        {frequencyRecords.map((d, i) => {
          const x = scaleX(d.occupancy);
          const y = scaleY(d.occurrences);
          return (
            <rect
              key={i}
              x={x}
              y={y}
              rx={2}
              width={scaleX.bandwidth()}
              height={Math.max(0, scaleY(0) - y)}
              fill={colors.Blue400}
            />
          );
        })}
        <g
          transform={`translate(0, ${height - padding.bottom})`}
          ref={xAxisGroupElementRef}
        />
      </svg>
    </Box>
  );
};
