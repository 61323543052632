import { createTheme } from "@mui/material";
import * as dust from "@density/dust/dist/tokens/dust.tokens";

export const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: "'Aeonik', sans-serif",
  },
  palette: {
    text: {
      primary: dust.Gray900,
    },
    primary: {
      main: dust.Blue400,
      light: dust.Blue200,
      dark: dust.Blue600,
    },
    secondary: {
      main: dust.Purple400,
      light: dust.Purple200,
      dark: dust.Purple600,
    },
    grey: {
      50: dust.Gray000,
      100: dust.Gray100,
      200: dust.Gray200,
      300: dust.Gray300,
      400: dust.Gray400,
      500: dust.Gray500,
      600: dust.Gray600,
      700: dust.Gray700,
      800: dust.Gray800,
      900: dust.Gray900,
    },
  },
});
