// Adapted from https://github.com/duckdb/duckdb-wasm/blob/952312ac818bc0850626b4810dc87450f65cea08/packages/react-duckdb/src/resolvable.ts

import { useState } from "react";

export namespace Resolvable {
  export enum Status {
    NONE,
    RUNNING,
    FAILED,
    COMPLETED,
  }

  export function completeWith<Value>(value: Value) {
    return {
      status: Status.COMPLETED as const,
      value,
    };
  }

  export function failWith<Error = unknown>(error: Error) {
    return {
      status: Status.FAILED as const,
      error,
    };
  }

  export function updateRunning() {
    return {
      status: Status.RUNNING as const,
    };
  }

  export function render<Value, Error = unknown>(
    resolvable: Resolvable<Value, Error>,
    onNone: () => React.ReactNode,
    onRunning: () => React.ReactNode,
    onFailed: (error: Error) => React.ReactNode,
    onCompleted: (value: Value) => React.ReactNode
  ) {
    switch (resolvable.status) {
      case Status.NONE:
        return onNone();
      case Status.RUNNING:
        return onRunning();
      case Status.FAILED:
        return onFailed(resolvable.error);
      case Status.COMPLETED:
        return onCompleted(resolvable.value);
    }
  }
}

export type Resolvable<Value, Error = unknown> =  //eslint-disable-line
  | { status: Resolvable.Status.NONE }
  | { status: Resolvable.Status.RUNNING }
  | { status: Resolvable.Status.FAILED; error: Error }
  | { status: Resolvable.Status.COMPLETED; value: Value };

export function useResolvable<T>() {
  const [state, setState] = useState<Resolvable<T>>({
    status: Resolvable.Status.NONE,
  });
  return [state, setState] as const;
}
