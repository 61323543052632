import { DatabaseContext } from "lib/contexts";
import { useDuckDB } from "lib/db";
import { Resolvable } from "lib/resolvable";
import { Wrapper } from "lib/types";
import { ErrorState } from "components/error-state";
import { Loader } from "components/loader";

export const DatabaseLoader: Wrapper = (props) => {
  const resolveDB = useDuckDB();

  switch (resolveDB.status) {
    case Resolvable.Status.NONE:
    case Resolvable.Status.RUNNING: {
      return <Loader>Loading database...</Loader>;
    }
    case Resolvable.Status.FAILED: {
      return <ErrorState>Error loading database.</ErrorState>;
    }
    case Resolvable.Status.COMPLETED: {
      const { value } = resolveDB;

      return (
        <DatabaseContext.Provider value={value}>
          {props.children}
        </DatabaseContext.Provider>
      );
    }
  }
};
