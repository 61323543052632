import React, { Reducer, useCallback, useContext, useReducer } from "react";
import { PlanSelectionContext } from "../../lib/contexts";

export type SpaceSelectionActions = {
  onAreaMouseEnter: (spaceId: string) => void;
  onAreaMouseLeave: (spaceId: string) => void;
  onAreaClick: (spaceId: string) => void;
  onBackgroundClick: () => void;
  onEscapeKeyDown: () => void;
};

export type SpaceSelection = [
  state: SpaceSelectionState,
  actions: SpaceSelectionActions
];

export function getFocusedSpaceId(spaceSelectionState: SpaceSelectionState) {
  switch (spaceSelectionState.status) {
    case "no-selection":
      return null;
    case "selection-only":
      return spaceSelectionState.selectedSpaceId;
    case "selection-and-peek":
      return spaceSelectionState.selectedSpaceId;
    case "peek-only":
      return spaceSelectionState.peekedSpaceId;
  }
}

export const SpaceSelectionContext = React.createContext<SpaceSelection>(
  null as any as SpaceSelection
);

export type SpaceSelectionState =
  | {
      status: "no-selection";
    }
  | {
      status: "peek-only";
      peekedSpaceId: string;
    }
  | {
      status: "selection-only";
      selectedSpaceId: string;
    }
  | {
      status: "selection-and-peek";
      selectedSpaceId: string;
      peekedSpaceId: string;
    };

type SpaceSelectionAction =
  | { type: "selection.reset" }
  | {
      type: "area.mouseenter";
      spaceId: string;
    }
  | {
      type: "area.mouseleave";
      spaceId: string;
    }
  | {
      type: "area.click";
      spaceId: string;
    }
  | {
      type: "background.click";
    }
  | { type: "keyboard.escape" };

const reducer: Reducer<SpaceSelectionState, SpaceSelectionAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "area.mouseenter": {
      switch (state.status) {
        case "no-selection": {
          return {
            status: "peek-only",
            peekedSpaceId: action.spaceId,
          };
        }
        case "selection-only": {
          return {
            status: "selection-and-peek",
            selectedSpaceId: state.selectedSpaceId,
            peekedSpaceId: action.spaceId,
          };
        }
        default: {
          return state;
        }
      }
    }
    case "area.mouseleave": {
      switch (state.status) {
        case "peek-only": {
          return {
            status: "no-selection",
          };
        }
        case "selection-and-peek": {
          return {
            status: "selection-only",
            selectedSpaceId: state.selectedSpaceId,
          };
        }
        default: {
          return state;
        }
      }
    }
    case "area.click": {
      switch (state.status) {
        // probably not possible? how could you click without mouseover first?
        // in any case, force sanity if this does happen:
        case "no-selection": {
          return {
            status: "selection-only",
            selectedSpaceId: action.spaceId,
          };
        }
        // also probably not possible...
        case "selection-only": {
          if (state.selectedSpaceId !== action.spaceId) {
            return {
              status: "selection-only",
              selectedSpaceId: action.spaceId,
            };
          } else {
            return {
              status: "no-selection",
            };
          }
        }
        case "peek-only": {
          return {
            status: "selection-and-peek",
            selectedSpaceId: action.spaceId,
            peekedSpaceId: action.spaceId,
          };
        }
        case "selection-and-peek": {
          if (state.selectedSpaceId !== action.spaceId) {
            return {
              status: "selection-and-peek",
              selectedSpaceId: action.spaceId,
              peekedSpaceId: state.peekedSpaceId,
            };
          }
          return {
            status: "peek-only",
            peekedSpaceId: state.peekedSpaceId,
          };
        }
      }
      break;
    }
    case "background.click": {
      return {
        status: "no-selection",
      };
    }
    case "keyboard.escape": {
      switch (state.status) {
        case "selection-only": {
          return {
            status: "no-selection",
          };
        }
        case "selection-and-peek": {
          return {
            status: "peek-only",
            peekedSpaceId: state.peekedSpaceId,
          };
        }
        default: {
          return state;
        }
      }
    }
    case "selection.reset": {
      return {
        status: "no-selection",
      };
    }
  }
};

const initialState: SpaceSelectionState = {
  status: "no-selection",
};

export function useSpaceSelection(): [
  SpaceSelectionState,
  SpaceSelectionActions
] {
  const [planSelectionState] = useContext(PlanSelectionContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const onAreaMouseEnter = useCallback(
    (spaceId: string) => {
      dispatch({ type: "area.mouseenter", spaceId });
    },
    [dispatch]
  );
  const onAreaMouseLeave = useCallback(
    (spaceId: string) => {
      dispatch({ type: "area.mouseleave", spaceId });
    },
    [dispatch]
  );
  const onAreaClick = useCallback(
    (spaceId: string) => {
      dispatch({ type: "area.click", spaceId });
    },
    [dispatch]
  );
  const onBackgroundClick = useCallback(() => {
    dispatch({ type: "background.click" });
  }, [dispatch]);

  const onEscapeKeyDown = useCallback(() => {
    dispatch({ type: "keyboard.escape" });
  }, [dispatch]);

  const actions = {
    onAreaMouseEnter,
    onAreaMouseLeave,
    onAreaClick,
    onBackgroundClick,
    onEscapeKeyDown,
  };
  return [state, actions];
}
