import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider, CssBaseline } from "@mui/material";

import { App } from "app/app";
import { theme } from "app/theme";
import reportWebVitals from "./reportWebVitals";

// Styles
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@densityco/ui/styles.css";
import "@densityco/lib-common-auth/styles.css";
import "styles/font-face.css";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.Fragment>
);

// AWS.config.update({
//   region: "us-east-1",
//   accessKeyId: process.env.REACT_APP_BUCKET_ACCESS_KEY_ID,
//   secretAccessKey: process.env.REACT_APP_BUCKET_SECRET_ACCESS_KEY,
// });
// const s3 = new AWS.S3({
//   region: "us-east-1",
// });
// s3.listObjectsV2(
//   {
//     Bucket: "roi-prototype-data",
//   },
//   (err, data) => {
//     console.log("list", data);
//   }
// );
// s3.getObject(
//   { Bucket: "roi-prototype-data", Key: "plans.parquet" },
//   (err, data) => {
//     console.log("data", data);
//   }
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
