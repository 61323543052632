import { ListItem, Typography, Box } from "@mui/material";

export const MetricDisplay: React.FunctionComponent<
  React.PropsWithChildren & {
    label: string;
  }
> = ({ label, children }) => {
  return (
    <ListItem sx={{ display: "block" }}>
      <Typography variant="caption">{label}</Typography>
      <Box display={"block"}>{children}</Box>
    </ListItem>
  );
};
